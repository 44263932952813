import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RemoveUser from "../../removeUser/RemoveUser";

export default function List({ data, isdelete, setDelete }) {
    return (
        <>
            <ListContainer>
                <TitleContainer>
                    <Titleitem type="sl">{data.sl}</Titleitem>
                    <Titleitem type="name">{data.name}</Titleitem>
                    <Titleitem type="name">{data.email}</Titleitem>
                    <Titleitem type="name">+91 {data.phone}</Titleitem>
                    <Titleitem>
                        <ActionDiv>
                            <Icon to="/eyeviewpostscreen">
                                <img
                                    src={
                                        require("../../../../../assets/images/eye.svg")
                                            .default
                                    }
                                    alt="Image"
                                />
                            </Icon>
                            <Icon to="/edituser">
                                <img
                                    src={
                                        require("../../../../../assets/images/edit.svg")
                                            .default
                                    }
                                    alt="edit"
                                />
                            </Icon>
                            <IconDelete onClick={() => setDelete(!isdelete)}>
                                <img
                                    src={
                                        require("../../../../../assets/images/trash.svg")
                                            .default
                                    }
                                    alt="trash"
                                />
                            </IconDelete>
                        </ActionDiv>
                    </Titleitem>
                </TitleContainer>
            </ListContainer>
        </>
    );
}

const ListContainer = styled.div`
    width: 100%;
`;
const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 12px;
    align-items: center;
`;
const Titleitem = styled.span`
    margin-right: 10px;
    background: #fff;
    padding: 17px 37px;
    font-family: "poppins-regular";

    font-size: 15px;
    align-items: center;
    border-radius: 6px;
    width: ${({ type }) =>
        type === "sl" ? "9%" : type == "name" ? "35%" : "21%"};
    &:first-child {
        padding: 17px 15px;
    }
    &:last-child {
        margin-right: 0;
    }
`;
const ActionDiv = styled.div`
    display: flex;
`;
const Icon = styled(Link)`
    margin-right: 20px;
    width: 15px;
    &:last-child {
        margin-right: 0;
    }
`;
const IconDelete = styled.div``;
