import React, { useState } from "react";
import styled from "styled-components";
import Img from "../../../assets/images/arrow-down.svg";
import ArrowUp from "../../../assets/images/arrow-Up-green.svg";

export default function DashboardTopCards() {
    const [listitems, setListitems] = useState([
        {
            id: 1,
            Title: "Total no of students",
            Count: "738",
            Percentage: "0.7%",
            intigater: Img,
        },
        {
            id: 2,
            Title: "Active Students",
            Count: "582",
            Percentage: "0.7%",
            intigater: ArrowUp,
        },
        {
            id: 3,
            Title: "Active Contests",
            Count: "038",
            Percentage: "0.7%",
            intigater: Img,
        },
        {
            id: 4,
            Title: "DaisToT Active Contests ",
            Count: "025",
            Percentage: "0.7%",
            intigater: Img,
        },
    ]);
    const renderItems = () =>
        listitems.map((item) => {
            return (
                <ListItem key={item.id}>
                    <Title>{item.Title}</Title>
                    <Count>{item.Count}</Count>
                    <GrowthDiv>
                        <Percentage>{item.Percentage}</Percentage>
                        <GrowthIcon>
                            <img src={item.intigater} alt="Arrow" />
                        </GrowthIcon>
                    </GrowthDiv>
                </ListItem>
            );
        });
    return (
        <TopCardContainer>
            <HeadDiv>
                <Head>Dashboard</Head>
            </HeadDiv>

            <CardList>{renderItems()}</CardList>
        </TopCardContainer>
    );
}

const TopCardContainer = styled.div``;
const HeadDiv = styled.div`
    margin-bottom: 12px;
`;
const Head = styled.div`
    font-family: "poppins-semibold";
`;
const CardList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
`;
const ListItem = styled.div`
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 7px;
    padding: 15px 10px;
`;
const Title = styled.h3`
    text-align: center;
    font-family: "poppins-semibold";
    font-size: 18px;
    margin-bottom: 5px;
`;
const Count = styled.span`
    text-align: center;
    font-family: "poppins-semibold";
    font-size: 50px;
    color: linear-gradient(to right, #4684ef, #00b6f4);
    background: -webkit-linear-gradient(#4684ef, #00b6f4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const GrowthDiv = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;
const GrowthIcon = styled.div`
    width: 14px;
    margin-left: 4px;
`;
const Percentage = styled.span`
    font-size: 16px;
    font-family: "poppins-regular";
    color: #979797;
`;
