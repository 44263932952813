import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SelectWinnerModal from "../../../includes/contests/announcewinner/modal/SelectWinnerModal";

function ContextWinner() {
    const [isWinner, setWinner] = useState(false);
    const participant = [
        {
            id: 1,
            name: "Navas",
            dept: "cse",
            date: "12/02/22",
        },
        {
            id: 2,
            name: "dilshad",
            dept: "cse",
            date: "12/02/22",
        },
        {
            id: 3,
            name: "shiyas",
            dept: "ee",
            date: "15/02/22",
        },
        {
            id: 4,
            name: "shiyas",
            dept: "ee",
            date: "15/02/22",
        },
        {
            id: 5,
            name: "shiyas",
            dept: "ee",
            date: "15/02/22",
        },
        {
            id: 5,
            name: "shiyas",
            dept: "ee",
            date: "15/02/22",
        },
        {
            id: 5,
            name: "shiyas",
            dept: "ee",
            date: "15/02/22",
        },
    ];

    return (
        <>
            <SelectWinnerModal isWinner={isWinner} setWinner={setWinner} />
            <MainContainer id="center">
                <Header>
                    <h4>Select Winners</h4>
                </Header>
                <ContentContainer>
                    <Heading to="/win">Mappila pattu</Heading>
                    <Contest>
                        {participant.map((item) => (
                            <Li>
                                <ImageContainer>
                                    <img
                                        src={
                                            require("../../../../assets/images/Contest_mappipla.svg")
                                                .default
                                        }
                                        alt="Contest"
                                    />
                                </ImageContainer>
                                <BottomCotainer>
                                    <Details>
                                        <Name>{item.name}</Name>
                                        <Dept>{item.dept}</Dept>
                                        <Dept>{item.date}</Dept>
                                    </Details>
                                    <Details>
                                        <Button>Select</Button>
                                        <HeartContainer>
                                            <Heart>
                                                <img
                                                    src={
                                                        require("../../../../assets/images/heart.svg")
                                                            .default
                                                    }
                                                    alt="Heart"
                                                />
                                            </Heart>
                                            <Name>1232</Name>
                                        </HeartContainer>
                                    </Details>
                                </BottomCotainer>
                            </Li>
                        ))}
                    </Contest>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default ContextWinner;

const MainContainer = styled.div`
    width: 80%;
    height: 100vh;
    margin-top: 0 !important;
    margin-left: 280px !important;
`;
const Header = styled.div`
    text-align: center;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    width: 100%;
    h4 {
        font-size: 20px;
        color: #000;
        padding: 15px;
    }
`;
const Heading = styled(Link)`
    font-size: 20px;
    color: #000;
`;
const ContentContainer = styled.div`
    padding: 20px;
`;
const Contest = styled.ul`
    padding-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
    /* display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: 186px 186px 186px 186px; */
`;
const Li = styled.li`
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 0px 0px 12px 12px;
    background: #fff;
   
`;
const ImageContainer = styled.div`
    width: 100%;
    /* margin-bottom: 20px; */
    border-radius: 12px 12px 0px 0px;
    /* border-top-left-radius: 12px; */
    overflow: hidden;
    img {
    }
`;
const BottomCotainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
`;
const Details = styled.div``;
const Name = styled.p`
    font-size: 14px;
    font-family: "poppins-Bold";
    color: #000;
`;
const Dept = styled.p`
    font-size: 14px;
    color: #bdbdbd;
`;
const Button = styled.div`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 6px;
    width: 90px;
    font-size: 14px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
`;
const HeartContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;
const Heart = styled.div`
    width: 25%;
    margin-right: 8px;
    cursor: pointer;
`;
