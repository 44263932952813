import React, { useState } from "react";
import styled from "styled-components";
import DatasImportedSuccess from "./DatasImportedSuccess";

function SelectExcelFile({ iscontact, setContact }) {
    const [issuccess, setSuccess] = useState(false);
    return (
        <>
            <DatasImportedSuccess
                issuccess={issuccess}
                setSuccess={setSuccess}
            />
            <Overlay className={iscontact ? "active" : ""}></Overlay>
            <MainContainer className={iscontact ? "active" : ""}>
                <ContentContainer>
                    <Top>
                        <p>Select Your Excel File </p>
                        <Upload>
                            <input type="file" />
                            Upload
                        </Upload>
                    </Top>
                    <Buttons>
                        <Button
                            onClick={() => {
                                setSuccess(true);
                                setContact(false);
                            }}
                        >
                            SUBMIT
                        </Button>
                    </Buttons>
                    <CloseContainer
                        onClick={() => {
                            setContact(false);
                        }}
                    >
                        <img
                            src={
                                require("../../../../../assets/images/x.svg")
                                    .default
                            }
                            alt="X"
                        />
                    </CloseContainer>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default SelectExcelFile;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 501;
    position: fixed;
    top: 0;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 502;
    transform: scale(0);
    transition: 0.3s ease-in-out;
    &.active {
        transform: scale(1);
    }
`;

const ContentContainer = styled.div`
    width: 30%;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    padding: 48px 30px 24px 30px;
    text-align: center;
    position: relative;
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    p {
        color: #000000;
        font-size: 14px;
        opacity: 0.5;
        line-height: 1.5;
    }
`;
const Top = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid grey;
    padding: 5px 0px;
`;
const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    color: #000;
`;
const Button = styled.div`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 5px;
    padding: 6px 20px;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
`;
const Upload = styled.div`
    background: #dcedf4;
    color: #202020;
    border-radius: 5px;
    cursor: pointer;
    padding: 6px 16px;
    position: relative;
    input {
        position: absolute;
        cursor: pointer;
        width: 100%;
        padding: 5px;
        top: 0;
        left: 0;
        opacity: 0;
    }
    @media all and (max-width: 1440px) {
        font-size: 13px;
    }
`;
const CloseContainer = styled.div`
    width: 5%;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
`;
