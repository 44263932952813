import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import styled from "styled-components";

function LineChartGraph({}) {
    Chart.register(...registerables);

    const data = {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        datasets: [
           
            {
                data: [100, 50, 60, 15, 40, 30, 25, 80, 70, 20, 55, 70],
                borderColor: [
                    "rgba(54, 162, 235, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            drawBorder: true,
            title: {
                display: false,
                text: "Contests",
            },
        },
        layout: {
            padding: 10,
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: true,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: true,
                },
            },
        },
    };

    return (
        <Container>
            <Line data={data} options={options} />
        </Container>
    );
}

export default LineChartGraph;

const Container = styled.div`
    overflow: hidden;
    canvas {
        box-sizing: border-box;
        display: block;
        height: 340px;
        width: 545px;
        overflow: hidden;
        padding: 30px;
    }
`;
