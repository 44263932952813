import { CategoryScale } from "chart.js";
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProgramContext } from "../../../App";
import DanceBackground from "../../../assets/images/banner.svg";
import CardList from "../../includes/contests/contestscreen/CardList";

export default function SubmissionScreen({ category }) {
    const { programCategory } = useContext(ProgramContext);
    return (
        <>
            <TopBanner>
                <LeftContainer>
                    <LeftArrow to="/contestscreen">
                        <img
                            src={
                                require("../../../assets/images/arrow-left.svg")
                                    .default
                            }
                            alt="left-arrow"
                        />
                    </LeftArrow>
                </LeftContainer>
                <MiddileContainer>
                    <ItemName>Cinematic Dance</ItemName>
                    <Category>Dancing</Category>
                    <DayandPostContiner>
                        <Day>2 days Left </Day>
                        <Post>100+ posts</Post>
                    </DayandPostContiner>
                </MiddileContainer>
                <RightContainer>
                    <img
                        src={
                            require("../../../assets/images/more-horizontal (1).svg")
                                .default
                        }
                        alt="more"
                    />
                </RightContainer>
            </TopBanner>
            <SubmissionScreenContainer id="center">
                <SubHeadList>
                    <SubHead type="sl">Most Liked Entries</SubHead>
                    <SubHead>Recent Entries</SubHead>
                    <SubHead>starred Entries</SubHead>
                </SubHeadList>
                <CardList category={category} />
            </SubmissionScreenContainer>
        </>
    );
}

const SubmissionScreenContainer = styled.div``;
const TopBanner = styled.div`
    display: flex;
    justify-content: space-between;
    background-image: url(${DanceBackground});
    background-position: center;
    background-size: cover;
    height: 150px;
    width: calc(100% - 280px);
    margin-bottom: 15px;
    margin-left: 280px;
`;
const LeftContainer = styled.div``;
const LeftArrow = styled(Link)`
    width: 20px;
    margin: 17px 0px 0 11px;
`;
const MiddileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const ItemName = styled.span`
    color: #fff;
    margin-bottom: 5px;
`;
const Category = styled.div`
    color: #fff;
`;
const DayandPostContiner = styled.div`
    display: flex;
`;
const RightContainer = styled.div`
    width: 20px;
    width: 20px;
    margin: 17px 29px 0 0;
`;
const Day = styled.div`
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
`;
const Post = styled.div`
    color: #fff;
    font-size: 14px;
`;
const SubHeadList = styled.div`
    display: flex;
    background: #ffffff;
    border-radius: 14px;
    width: 50%;
    padding: 7px 11px;
    margin-bottom: 15px;
`;
const SubHead = styled.span`
    background: #ffffff;
    border-radius: 14px;
    text-align: center;
    margin: 0 auto;
    padding: 4px 20px;
    font-size: 15px;
    font-family: "poppins-regular";
    line-height: 1.3;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: #eff3f9;
    }
`;
