import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export default function PostMenuTab() {
    return (
        <Tabdiv>
            <SubHeadList>
                <SubHead to="/eyeviewpostscreen" activeClassName="active">
                    Posts
                </SubHead>
                <SubHead to="/eyeviewContestscreen" activeClassName="active">
                    Contests
                </SubHead>
            </SubHeadList>
        </Tabdiv>
    );
}
const SubHeadList = styled.div`
    display: flex;
    background: #ffffff;
    border-radius: 14px;
    padding: 4px 47px;
    width: 23%;
    position: fixed;
    z-index: 10;
    margin-bottom: 10px;
`;
const SubHead = styled(NavLink)`
    margin-left: 10px;
    background: #ffffff;
    border-radius: 14px;
    text-align: center;
    margin: 0 auto;
    padding: 4px 15px;
    font-size: 15px;
    font-family: "poppins-regular";
    line-height: 1.3;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    &:last-child {
        margin-right: 0;
    }
    &.active {
        background: #eff3f9;
    }
`;
const Tabdiv = styled.div`
    width: 100%;
`;
