import React from "react";
import { useState } from "react";
import styled from "styled-components";
import BackGround from "../../../../assets/images/dropdown.svg";
import AddStudent from "../../../includes/User/adduser/AddStudent";
import SelectExcelFile from "../../../includes/User/adduser/Modals/SelectExcelFile";
import UserAdded from "../../../includes/User/adduser/Modals/UserAdded";

function AddUser() {
    const [iscontact, setContact] = useState(false);
    const [issave, setSave] = useState(false);
    return (
        <>
            <SelectExcelFile iscontact={iscontact} setContact={setContact} />
            <AddStudent iscontact={iscontact} setContact={setContact} />

            <MainContainer id="center">
                <Top>
                    <HeaderLeft>
                        <h3>Add User</h3>
                    </HeaderLeft>
                    <HeaderRight>
                        <FileImageConatiner>
                            <img
                                src={
                                    require("../../../../assets/images/fileImage.svg")
                                        .default
                                }
                                alt=""
                            />
                        </FileImageConatiner>
                        <p>Import students data</p>
                    </HeaderRight>
                </Top>
                <FromContainer>
                    {/* UserId,UserName */}
                    <Input>
                        <label>
                            User ID ( Auto generated )
                            <input type="text" name="name" />
                        </label>
                        <label className="password">
                            User Name
                            <input type="text" name="name" />
                        </label>
                    </Input>
                    {/* Class,Division */}
                    <Input>
                        <label>
                            class*
                            <select
                                id="class"
                                name="classlist"
                                form="classform"
                            >
                                <option value="volvo"></option>
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="opel">Opel</option>
                                <option value="audi">Audi</option>
                            </select>
                        </label>
                        <label>
                            Division
                            <select>
                                <option value="0"></option>
                                <option value="1">A</option>
                                <option value="2">B</option>
                                <option value="3">c</option>
                                <option value="4">D</option>
                            </select>
                        </label>
                    </Input>
                    {/* DOB,Phone */}
                    <Input>
                        <label>
                            DOB*
                            <input type="date" name="name" />
                        </label>
                        <label>
                            Phone No*
                            <input type="Number" name="name" />
                        </label>
                    </Input>
                    {/* Email,City */}
                    <Input>
                        <label>
                            Email ID*
                            <input type="Email" name="name" required />
                        </label>
                        <label>
                            city
                            <input type="text" name="name" />
                        </label>
                    </Input>
                    {/* Addres,Country*/}
                    <Input>
                        <label>
                            Address
                            <input type="text" name="name" />
                        </label>
                        <label>
                            Country
                            <select>
                                <option value="0">Select Country</option>
                                <option value="1">A</option>
                                <option value="2">B</option>
                                <option value="3">c</option>
                                <option value="4">D</option>
                            </select>
                        </label>
                    </Input>
                    {/* State,Pincode */}
                    <Input>
                        <label>
                            State
                            <select>
                                <option value="0">Select State</option>
                                <option value="1">A</option>
                                <option value="2">B</option>
                                <option value="3">c</option>
                                <option value="4">D</option>
                            </select>
                        </label>
                        <label>
                            Pincode
                            <input type="Number" name="name" />
                        </label>
                    </Input>
                    <Input className="Upload">
                        <label>
                            ID Card
                            <input type="Number" name="name" />
                        </label>
                        <Upload>
                            <input type="file" />
                            Upload
                        </Upload>
                    </Input>
                    <h4>Login Credential</h4>
                    <Input>
                        <label>
                            Username*
                            <input type="text" name="name" />
                        </label>
                        <label className="Password">
                            Password*
                            <input
                                type="Password"
                                placeholder="Password"
                                name="name"
                                required
                            />
                            <Eye>
                                <img
                                    src={
                                        require("../../../../assets/images/eye-1.svg")
                                            .default
                                    }
                                    alt="Eye"
                                />
                            </Eye>
                        </label>
                    </Input>
                </FromContainer>
                <Button onClick={() => setSave(!issave)}>SAVE</Button>
            </MainContainer>
            <UserAdded issave={issave} setSave={setSave} />
        </>
    );
}

export default AddUser;

const MainContainer = styled.div`
    padding: 20px 0px 8px 25px;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HeaderLeft = styled.div`
    h3 {
        color: #000;
    }
`;
const HeaderRight = styled.div`
    width: 220px;
    height: 35px;
    background: #20af51;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    p {
        font-size: 14px;
        color: #fff;
    }
`;
const FileImageConatiner = styled.div`
    width: 7%;
    margin-right: 10px;
`;
const FromContainer = styled.div`
    width: 90%;
    margin-top: 40px;
    h4 {
        font-size: 14px;
        font-family: "poppins-semibold";
        color: black;
        margin-bottom: 20px;
    }
`;
const Input = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 30px;
    label {
        font-size: 14px;
        color: grey;
        width: 50%;
        &.Password {
            position: relative;
        }
    }
    input {
        width: 90%;
        display: flex;
        border-bottom: 1px solid grey;
        margin-top: 16px;
        align-items: flex-end;
    }
    select {
        width: 90%;
        display: flex;
        border-bottom: 1px solid grey;
        margin-top: 10px;
        background-image: url(${BackGround});
        background-repeat: no-repeat;
        background-position: right;
        background-size: 10px;
        font-size: 14px;
    }
    &.Upload {
        position: relative;
    }
`;
const Upload = styled.div`
    background: #d2e1fb;
    color: #202020;
    border-radius: 5px;
    cursor: pointer;
    padding: 6px 16px;
    position: absolute;
    top: 15px;
    left: 36%;
    input {
        position: absolute;
        cursor: pointer;
        width: 100%;
        padding: 5px;
        top: 0;
        left: 0;
        opacity: 0;
        width: 90%;
        display: flex;
        border-bottom: 1px solid grey;
        margin-top: 16px;
        align-items: flex-end;
    }
    @media all and (max-width: 1440px) {
        font-size: 13px;
    }
`;
const Eye = styled.div`
    width: 18px;
    cursor: pointer;
    top: 33px;
    position: absolute;
    right: 49px;
`;
const Button = styled.div`
    height: 40px;
    width: 15%;
    font-size: 14px;
    color: #fff;
    display: flex;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
`;
