import React from "react";
import styled from "styled-components";
import Contestlist from "../../includes/contests/contestscreen/Contestlist";
import { Link, Outlet } from "react-router-dom";
import ContestMenuTab from "../../includes/contests/ContestMenuTab";
import Pagination from "../../includes/User/userscreen/Pagination";

export default function ContestScreen() {
    const datas = [
        {
            sl: "001",
            Contestname: "Dance Contest",
            Category: "dance",
            Subcategory: "Cinimatic Dance",
            Status: "Ongoing",
            EndDate: "05-07-2022",
        },
        {
            sl: "001",
            Contestname: "Dance Contest",
            Category: "Dance",
            Subcategory: "Cinimatic Dance",
            Status: "Ongoing",
            EndDate: "05-07-2022",
        },
        {
            sl: "001",
            Contestname: "Dance Contest",
            Category: "Dance",
            Subcategory: "Cinimatic Dance",
            Status: "Ongoing",
            EndDate: "05-07-2022",
        },
        {
            sl: "001",
            Contestname: "Dance Contest",
            Category: "Dance",
            Subcategory: "Cinimatic Dance",
            Status: "Ongoing",
            EndDate: "05-07-2022",
        },
        {
            sl: "001",
            Contestname: "Dance Contest",
            Category: "Dance",
            Subcategory: "Cinimatic Dance",
            Status: "Ongoing",
            EndDate: "05-07-2022",
        },
        {
            sl: "001",
            Contestname: "Dance Contest",
            Category: "Dance",
            Subcategory: "Cinimatic Dance",
            Status: "Ongoing",
            EndDate: "05-07-2022",
        },
    ];
    return (
        <>
            <UserScreenContainer id="center">
                <TopPortion>
                    <Head>
                        <Title>Contest</Title>
                        <RightContainer>
                            <Result>Show results</Result>
                            <AddUserButton>+ Add User</AddUserButton>
                            <FilterButton>
                                <FilterIcon>
                                    <img
                                        src={
                                            require("../../../assets/images/Iconly.svg")
                                                .default
                                        }
                                    />
                                </FilterIcon>
                                <Name>filter</Name>
                            </FilterButton>
                        </RightContainer>
                    </Head>

                    <ContestMenuTab />
                </TopPortion>
                <ListContainer>
                    <TitleContainer>
                        <Titleitem type="sl">Id No.</Titleitem>
                        <Titleitem type="name">Contest Name</Titleitem>
                        <Titleitem type="name">Category</Titleitem>
                        <Titleitem type="name">Sub-category</Titleitem>
                        <Titleitem>Status</Titleitem>
                        <Titleitem>End-Date</Titleitem>
                        <Titleitem>Action</Titleitem>
                    </TitleContainer>
                </ListContainer>
                {datas.map((item) => (
                    <Contestlist data={item} />
                ))}
                <BottomDiv>
                    <Pagination />
                </BottomDiv>
            </UserScreenContainer>
        </>
    );
}

const Counttext = styled.span`
    font-size: 14px;
    margin-bottom: 10px;
`;
const CountDiv = styled.div``;
const ListContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;
const TitleContainer = styled.div`
    display: flex;
`;
const Titleitem = styled.span`
    background: #4187f0;
    margin-right: 10px;
    color: #fff;
    padding: 17px 23px;
    font-size: 15px;
    align-items: center;
    border-radius: 6px;
    width: ${({ type }) =>
        type === "sl" ? "11%" : type == "name" ? "35%" : "22%"};
    &:first-child {
        padding: 17px 7px 16px 12px;
    }
    &:last-child {
        margin-right: 0;
    }
`;
const UserScreenContainer = styled.div``;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TopPortion = styled.div`
    width: 98%;
`;
const LeftContainer = styled.div`
    width: 50%;
`;
const Title = styled.h4`
    font-family: "poppins-regular";
    font-size: 25px;
    color: #000;
`;
const SubHeadList = styled.div`
    display: flex;
    background: #ffffff;
    border-radius: 14px;
    width: 50%;
    padding: 7px 11px;
`;
const SubHead = styled.span`
    background: #ffffff;
    border-radius: 14px;
    text-align: center;
    margin: 0 auto;
    padding: 4px 15px;
    font-size: 15px;
    font-family: "poppins-regular";
    line-height: 1.3;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: #eff3f9;
    }
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 31%;
`;
const Result = styled.span`
    font-size: 16px;
    font-family: "poppins-regular";
`;
const AddUserButton = styled.div`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 4px;
    padding: 5px 15px;
    color: #fff;
    font-size: 12px;
`;
const PlusIcon = styled.div``;
const ButtonName = styled.span``;
const FilterButton = styled.div`
    display: flex;
    background: #fff;
    border-radius: 4px;
    padding: 5px 14px;
    width: 31%;
    font-size: 12px;
    align-items: center;
    font-family: "poppins-regular";
`;
const FilterIcon = styled.div`
    width: 15px;
    margin-right: 10px;
`;
const Name = styled.span`
    font-size: 12px;
    font-family: "poppins-regular";
`;
const BottomDiv = styled.div`
    float: right;
    display: flex;
    justify-content: space-between;
    width: 25%;
`;
const PreviousDiv = styled.div`
    display: flex;
    align-items: center;
`;
const Arrow = styled.div`
    width: 20px;
    margin-right: 10px;
`;
const Prvnme = styled.span`
    font-size: 15px;
`;
const Count = styled.div`
    background: #fff;
    background: #fff;
    padding: 1px 10px;
    font-size: 16px;
`;
const NextDiv = styled.div``;
const Next = styled.span`
    font-size: 15px;
`;
const ArrowRight = styled.div`
    width: 20px;
    margin-left: 10px;
`;
const SubheadDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    width: 100%;
`;
const ViewButtonDiv = styled.div`
    display: flex;
`;
const Grid = styled(Link)`
    margin-right: 10px;
    margin-right: 10px;
    width: 20px;
`;
const List = styled.div`
    margin-right: 10px;
    width: 20px;
`;
