import React from "react";
import { useState } from "react";
import styled from "styled-components";
import UserRemovedsuccess from "./modals/UserRemovedsuccess";

function RemoveUser({ isdelete, setDelete }) {
    const [issuccess, setSuccess] = useState(false);
    return (
        <>
            <Overlay className={isdelete ? "active" : ""}></Overlay>
            <MainContainer className={isdelete ? "active" : ""}>
                <ContentContainer>
                    <p>Are you sure you want to remove this user ?</p>
                    <Buttons>
                        <Button onClick={() => setDelete(!isdelete)}>
                            cancel
                        </Button>
                        <Button
                            className="Remove"
                            onClick={() => {
                                setSuccess(!issuccess);
                                setDelete(!isdelete);
                            }}
                        >
                            Remove
                        </Button>
                    </Buttons>
                </ContentContainer>
            </MainContainer>
            <UserRemovedsuccess setSuccess={setSuccess} issuccess={issuccess} />
        </>
    );
}

export default RemoveUser;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1;
    display: none;
    &.active {
        display: block;
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 2;
    transform: scale(0);
    transition: 0.3s ease-in-out;
    &.active {
        transform: scale(1);
    }
`;

const ContentContainer = styled.div`
    width: 25%;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    padding: 40px 15px 15px 15px;
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        font-size: 14px;
        @media all and (max-width: 1440px) {
            font-size: 13px;
        }
    }
`;
const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    color: #000;
`;
const Button = styled.div`
    cursor: pointer;
    font-size: 14px;
    padding: 10px 10px;
    @media all and (max-width: 1440px) {
        font-size: 13px;
    }
    &.Remove {
        margin-left: 20px;
        background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
        border-radius: 12px;
        padding: 10px 10px;
        color: #fff;
    }
`;
