import { React, useState } from "react";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";

function Login({ isLogin, setLogin }) {
    const [view, setView] = useState(false);
    return (
        <>
            <MainContainer>
                <CardContainer>
                    <CardTop>
                        <LogoDiv>
                            <img
                                src={require("../../../assets/images/logooo.svg").default}
                                alt="Logo"
                            />
                        </LogoDiv>
                    </CardTop>
                    <CardMiddle>
                        <h3>LOG IN</h3>
                        <FormContents>
                            <form>
                                <label>
                                    <Avtar>
                                        <img
                                            src={
                                                require("../../../assets/images/Profile.svg")
                                                    .default
                                            }
                                            alt="Profile"
                                        />
                                    </Avtar>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        name="name"
                                    />
                                </label>
                                <label>
                                    <Lock>
                                        <img
                                            src={
                                                require("../../../assets/images/lock.svg")
                                                    .default
                                            }
                                            alt=""
                                        />
                                    </Lock>
                                    <input
                                         type={view ? "text" : "password"} name="name"
                                        placeholder="Password"
                                    />
                                    <Eye onClick={()=>setView(!view)}>
                                        <img
                                            src={
                                                require("../../../assets/images/eye.svg")
                                                    .default
                                            }
                                            alt="Eye"
                                        />
                                    </Eye>
                                </label>
                            </form>
                        </FormContents>
                    </CardMiddle>
                    <CardBottom>
                        <Button to="/" onClick={() => setLogin(false)}>
                            LOG IN
                        </Button>
                    </CardBottom>
                </CardContainer>
            </MainContainer>
            <Outlet />
        </>
    );
}

export default Login;

const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CardContainer = styled.div`
    width: 30%;
    background: #ffffff;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.09);
    border-radius: 26px;
    text-align: center;
    padding: 35px 31px;
    @media all and (max-width: 980px) {
        width: 30%;
    }
    @media all and (max-width: 768px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
`;
const CardTop = styled.div`
    margin-bottom: 30px;
`;
const CardMiddle = styled.div`
    h3 {
        margin-bottom: 35px;
        font-size: 20px;
        color: #007aa4;
        font-family: "poppins-regular";
    }
`;
const LogoDiv = styled.div`
    width: 40%;
    margin: 0 auto;
    img {
        display: block;
        width: 100%;
    }
`;
const FormContents = styled.div`
    form {
        label {
            display: flex;
            border: 1px solid #4684ef;
            align-items: center;
            margin: 0 auto;
            padding: 15px 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            input {
                font-size: 16px;
                width: 100%;
            }
            input,
            input::placeholder {
                font-size: 16px;
                font-family: "poppins-regular";
            }
        }
    }
`;
const Avtar = styled.div`
    width: 23px;
    margin-right: 26px;
    img {
        display: block;
        width: 100%;
    }
`;
const Lock = styled.div`
    width: 23px;
    margin-right: 26px;
    img {
        display: block;
        width: 100%;
    }
`;
const CardBottom = styled.div``;
const Button = styled(Link)`
    height: 48px;
    font-size: 16px;
    color: #fff;
    display: flex;
    margin: 0 auto;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    font-family: "poppins-regular";
`;
const Eye = styled.div`
    width: 18px;
    cursor: pointer;
`;
