import { React, useContext, useState } from "react";
import styled from "styled-components";
import ContestScreen from "./ContestScreen";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { CategoryScale } from "chart.js";
import dancepostthumb from "../../../assets/images/dancetumb.svg";
import monoactpostthumb from ".././../../assets/images/monoactthumb.svg";
import westerndancethumb from "../../../assets/images/westerndance.svg";
import Pencildrawingthumb from "../../../assets/images/pecildrawingthumb.svg";
import mapilapattuthumb from "../../../assets/images/mapilappatu.svg";
import ContestMenuTab from "../../includes/contests/ContestMenuTab";
import watercolorthumb from "../../../assets/images/watercolorthumb.svg";
import SubmissionScreen from "./SubmissionScreen";
import { ProgramContext } from "../../../App";

export default function () {
    const { cat } = useParams();
    const datas = [
        {
            PostThumb: dancepostthumb,
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            PostThumb: monoactpostthumb,
            Contestname: "Mono-Act",
            Category: "Mono-Act",
            Count: "1 days left",
            Day: "116 Posts",
        },
        {
            PostThumb: westerndancethumb,
            Contestname: "Western Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            PostThumb: mapilapattuthumb,
            Contestname: "Mappila Pattu",
            Category: "Song",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            PostThumb: Pencildrawingthumb,
            Contestname: "Pencil Drawing",
            Category: "Drawing",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            PostThumb: watercolorthumb,
            Contestname: "Water Color",
            Category: "Drawing",
            Count: "2 days left",
            Day: "136 Posts",
        },
    ];
    const { setProgramCategory } = useContext(ProgramContext);

    return (
        <>
            <ContestScreenGridContainer id="center">
                <TopSection>
                    <HeadDiv>
                        <Tittle>Contests</Tittle>
                        <Button>+ Create Contest</Button>
                    </HeadDiv>
                    <ContestMenuTab />
                </TopSection>

                <PostList>
                    {datas.map((item) => (
                        <>
                            <PostItem>
                                <TopDiv
                                    style={{
                                        backgroundImage: `url(${item.PostThumb})`,
                                    }}
                                >
                                    <Content>
                                        <Contestname>
                                            {item.Contestname}
                                        </Contestname>
                                        <Category>{item.Category}</Category>
                                        {/* {setCategory(item.Category)} */}
                                        <PostCountdiv>
                                            <Count>{item.Count}</Count>
                                            <Days>{item.Day}</Days>
                                        </PostCountdiv>
                                    </Content>
                                </TopDiv>
                                <BottomDiv>
                                    <Buttons>
                                        <EditButton>Edit</EditButton>
                                        <Winner to="/frist">
                                            Announce winner
                                        </Winner>
                                    </Buttons>
                                    <ViewButton
                                        to={`/Submission/${item.Category}`}
                                        onClick={() =>
                                            setProgramCategory(item.Category)
                                        }
                                    >
                                        VIEW SUBMISSIONS
                                    </ViewButton>
                                </BottomDiv>
                            </PostItem>
                        </>
                    ))}
                </PostList>
            </ContestScreenGridContainer>
        </>
    );
}

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const ContestScreenGridContainer = styled.div``;
const HeadDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TopSection = styled.div`
    width: 98%;
`;
const Tittle = styled.h3`
    font-family: "poppins-regular";
    font-size: 25px;
    color: #000;
`;
const Button = styled.div`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    padding: 8px 18px;
    font-size: 14px;
    color: #fff;
`;

const PostList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
`;
const PostItem = styled.div`
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
`;
const TopDiv = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    padding: 65px 0;
`;
const Category = styled.h3`
    color: #fff;
    font-size: 17px;
    font-family: "poppins-regular";
`;
const PostCountdiv = styled.div`
    display: flex;
`;
const Count = styled.span`
    color: #fff;
    font-size: 13px;
`;
const Days = styled.div`
    color: #fff;
    font-size: 13px;
    margin-left: 10px;
`;
const BottomDiv = styled.div`
    background: #fff;
    padding: 10px 20px;
`;
const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`;
const EditButton = styled.span`
    border-radius: 8px;
    padding: 9px 20px;
    line-height: 1;
    font-size: 13px;
    width: 45%;
    line-height: 1;
    background: -webkit-linear-gradient(#4684ef, #00b6f4);
    border: 1px solid #4684ef;
    text-align: center;
    cursor: pointer;
`;
const Winner = styled(Link)`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 12px;
    color: #fff;
    line-height: 1;
    cursor: pointer;
`;
const Contestname = styled.h3`
    text-align: center;
    font-family: "poppins-semibold";
    font-size: 15px;
    color: #fff;
`;
const ViewButton = styled(NavLink)`
    background: linear-gradient(90deg, #d7f8ff 0%, #e2f8ff 100%);
    border-radius: 12px;
    font-size: 13px;
    text-align: center;
    padding: 7px;
    display: block;
    color: #00b6f4;
    &:hover {
        background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
        color: #fff;
    }
`;
