import {React,useState} from 'react'
import styled from "styled-components";


export default function Pagination({currentpage,decNum,incNum,showitem}) {

  return (
    <BottomDiv>
    <PreviousDiv onClick={currentpage > 1 ? decNum : ""}>
        <Arrow>
            <img
                src={
                    require("../../../../assets/images/chevron-left.svg")
                        .default
                }
                alt="left-arrow"
            />
        </Arrow>
        <Prvnme>Previous</Prvnme>
    </PreviousDiv>
    <Count>{currentpage}</Count>
    <NextDiv onClick={showitem != null ? incNum:""}>
        <Next>Next</Next>
        <ArrowRight>
            <img
                src={
                    require("../../../../assets/images/chevron-right.svg")
                        .default
                }
                alt="Right-arrow"
            />
        </ArrowRight>
    </NextDiv>
</BottomDiv>
  )
}


const BottomDiv = styled.div`
    float: right;
    display: flex;
    justify-content: space-between;
    width: 15%;
    position: fixed;
    right: 40px;
    bottom: 5px;
    align-items: center;
 
`;
const PreviousDiv = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const Arrow = styled.div`
    width: 20px;
    margin-right: 5px;
`;
const Prvnme = styled.span`
    font-size: 15px;
`;
const Count = styled.div`
    background: #fff;
    background: #fff;
    padding: 1px 10px;
    font-size: 16px;
`;
const NextDiv = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const Next = styled.span`
    font-size: 15px;
`;
const ArrowRight = styled.div`
    width: 20px;
    margin-left: 2px;
`;