import React from "react";
import styled from "styled-components";

import { Link, Outlet } from "react-router-dom";

import mapilapattu from "../../../../assets/images/mapilappatu.svg";

export default function AnnounceWinnerScreen() {
    const datas = [
        {
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
        {
            Contestname: "Cinematic Dance",
            Category: "Dance",
            Count: "2 days left",
            Day: "136 Posts",
        },
    ];
    return (
        <AnnounceWinnerScreenDiv>
            <Head>
                <h4>Announce Winner</h4>
            </Head>
            <PostList id="center">
                {datas.map((item) => (
                    <PostItem>
                        <TopDiv>
                            <Content>
                                <Contestname>{item.Contestname}</Contestname>
                                <Category>{item.Category}</Category>
                                <PostCountdiv>
                                    <Count>{item.Count}</Count>
                                    <Days>{item.Day}</Days>
                                </PostCountdiv>
                            </Content>
                        </TopDiv>
                        <BottomDiv>
                            <ViewButton to="/selectwinner">
                                ANNOUNCE WINNER
                            </ViewButton>
                        </BottomDiv>
                    </PostItem>
                ))}
            </PostList>
        </AnnounceWinnerScreenDiv>
    );
}
const AnnounceWinnerScreenDiv = styled.div`
    padding-bottom: 20px;
`;
const Head = styled.div`
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    width: calc(100% - 280px);
    margin-left: 280px;
    h4 {
        font-size: 20px;
        color: #000;
        padding: 15px;
    }
`;
const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const PostList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
`;
const PostItem = styled.div`
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
`;
const TopDiv = styled.div`
    background-image: url(${mapilapattu});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 65px 0;
`;
const Category = styled.h3`
    color: #fff;
    font-size: 17px;
    font-family: "poppins-regular";
`;
const PostCountdiv = styled.div`
    display: flex;
`;
const Count = styled.span`
    color: #fff;
    font-size: 13px;
`;
const Days = styled.div`
    color: #fff;
    font-size: 13px;
    margin-left: 10px;
`;
const BottomDiv = styled.div`
    background: #fff;
    padding: 10px 20px;
`;
const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`;
const EditButton = styled.span`
    border-radius: 8px;
    padding: 9px 20px;
    font-size: 14px;
    line-height: 1;
    background: -webkit-linear-gradient(#4684ef, #00b6f4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 13px;
    border: 1px solid #4684ef;
    width: 45%;
    text-align: center;
    line-height: 1;
`;
const Winner = styled.span`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 12px;
    color: #fff;
    line-height: 1;
`;
const Contestname = styled.h3`
    text-align: center;
    font-family: "poppins-semibold";
    font-size: 15px;
    color: #fff;
`;
const ViewButton = styled(Link)`
    border: 1px solid #00b6f4;
    border-radius: 5px;
    font-size: 13px;
    text-align: center;
    padding: 7px;
    text-decoration: none;
    display: block;
    color: #00b6f4;
    width: 58%;
    margin: 0 auto;
`;
