import React, { useState } from "react";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import Notification from "../includes/dashboard/Notification";
import UserSidebar from "../includes/User/userscreen/modal/UserSidebar";

export default function SearchBar({ isHeader }) {
    const [isactive, setActive] = useState(false);
    const [isuser, setUser] = useState(false);
    return (
        <>
            <Notification isactive={isactive} setActive={setActive} />
            <UserSidebar isuser={isuser} setUser={setUser} />
            <Totaldiv id="search">
                <SearchBarContainer>
                    <LeftContainer>
                        <SearchDiv>
                            <SearchIcon>
                                <img
                                    src={
                                        require("../../assets/images/search.svg")
                                            .default
                                    }
                                    alt="Search-icon"
                                />
                            </SearchIcon>
                            <Searchinput>
                                <input type="text" placeholder="Search" />
                            </Searchinput>
                        </SearchDiv>
                    </LeftContainer>
                    <RightContainer>
                        <Icondiv>
                            <NotifiIconDiv onClick={() => setActive(!isactive)}>
                                <img
                                    src={
                                        require("../../assets/images/bell-Icon.svg")
                                            .default
                                    }
                                    alt="Notification-icon"
                                />
                            </NotifiIconDiv>
                            <ProfileIconDiv onClick={() => setUser(!isuser)}>
                                <img
                                    src={
                                        require("../../assets/images/Profile-icon.svg")
                                            .default
                                    }
                                    alt="Profile"
                                />
                            </ProfileIconDiv>
                        </Icondiv>
                    </RightContainer>
                </SearchBarContainer>
            </Totaldiv>
            <Outlet />
        </>
    );
}

const Totaldiv = styled.div``;

const SearchBarContainer = styled.div`
    width: calc(100%-330px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 500;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 7px;
`;
const LeftContainer = styled.div`
    width: 30%;
    padding-left: 20px;
`;
const SearchDiv = styled.div`
    display: flex;
    border: 1px solid #00b6f4;
    border-radius: 5px;
    align-items: center;
    padding: 5px 8px;
`;
const SearchIcon = styled.div`
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
`;
const Searchinput = styled.div`
    input{
        font-size: 16px;
        height: 20px;   
    }
    input::placeholder {
        font-size: 16px;
        height: 25px;
    }
`;
const RightContainer = styled.div`
    width: 7%;
`;
const Icondiv = styled.div`
    display: flex;
    align-items: center;
`;
const NotifiIconDiv = styled.div`
    width: 20px;
    cursor: pointer;
`;
const ProfileIconDiv = styled.div`
    margin-left: 22px;
    width: 32px;
    cursor: pointer;
`;
