import React from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
import Logout from "../../general/Authentication/Logout";
import Sidebar from "../../general/Sidebar";
import SearchBar from "../../general/SearchBar";
import RemoveUser from "../../includes/User/removeUser/RemoveUser";
import AddStudent from "../../includes/User/adduser/AddStudent";
import UserAdded from "../../includes/User/adduser/Modals/UserAdded";
import ContextCreateSuccess from "../../includes/contests/createcontest/modal/ContextCreateSuccess";
import DatasImportedSuccess from "../../includes/User/adduser/Modals/DatasImportedSuccess";
import UserRemovedsuccess from "../../includes/User/removeUser/modals/UserRemovedsuccess";
import SelectExcelFile from "../../includes/User/adduser/Modals/SelectExcelFile";
import RejectRegistration from "../../includes/pendingvarification/RejectRegistration";
import EditUserPassword from "../../includes/User/Edituser/EditUserPassword";
import Login from "../../general/Authentication/Login";
import AddUser from "../../screens/User/adduser/AddUser";
import Dashboard from "../../screens/dashboard/Dashboard";
import UserScreen from "../../screens/User/UserScreen";
import ContestScreen from "../../screens/contest/ContestScreen";
import UserSidebar from "../../includes/User/userscreen/modal/UserSidebar";
import ContestScreenGridView from "../../screens/contest/ContestScreenGridView";
import SubmissionScreen from "../../screens/contest/SubmissionScreen";
import Notification from "../../includes/dashboard/Notification";
import EditProfileData from "../../includes/User/Edituser/EditProfileData";
import CreateContest from "../../includes/contests/createcontest/CreateContest";
import SelectWinnerModal from "../../includes/contests/announcewinner/modal/SelectWinnerModal";
import Winner from "../../screens/contests/announcewinner/Winner";
import AnnounceWinnerScreen from "../../screens/contests/announcewinner/AnnounceWinnerScreen";
import DaistotContest from "../../screens/contests/daistotwinner/DaistotContest";
import ProfileCard from "../../includes/contests/contestscreen/ProfileCard";
import EyeViewPostScreen from "../../screens/contest/EyeViewPostScreen";
import EyeViewContestScreen from "../../screens/contest/EyeViewContestScreen";
import PostMenuTab from "../../includes/contests/contestscreen/PostMenuTab";
import ContestMenuTab from "../../includes/contests/ContestMenuTab";
import SelectWinners from "../../screens/contests/announcewinner/SelectWinners";
import UserVarification from "../../screens/pendingvarification/UserVarification";
import ContextWinner from "../../screens/contests/announcewinner/ContextWinner";
import EditUser from "../../screens/User/edituser/EditUser";

function AppRouter() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>DAISTOT</title>
                <link
                    rel="canonical"
                    href="../../../assets/images/daistot-new-logo (1).ico"
                />
            </Helmet>
            <Routes>
                <Route path="/" element={<SearchBar />}>
                    <Route path="/" element={<Sidebar />}>
                        <Route path="/Logout" element={<Logout />} />
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/user" element={<UserScreen />} />
                        <Route
                            path="/contestscreen"
                            element={<ContestScreen />}
                        />
                        <Route
                            path="/contestgrid"
                            element={<ContestScreenGridView />}
                        />
                        <Route path="/RemoveUser" element={<RemoveUser />} />
                        <Route path="/RemoveUser" element={<RemoveUser />} />

                        <Route
                            path="/Success"
                            element={<ContextCreateSuccess />}
                        />
                        <Route path="/Add" element={<AddStudent />} />
                        <Route path="/Added" element={<UserAdded />} />
                        <Route
                            path="/DatasImported"
                            element={<DatasImportedSuccess />}
                        />
                        <Route
                            path="/UserRemoved"
                            element={<UserRemovedsuccess />}
                        />
                        <Route path="/Excel" element={<SelectExcelFile />} />
                        <Route
                            path="/Reject"
                            element={<RejectRegistration />}
                        />
                        <Route
                            path="/EditUsermodal"
                            element={<EditProfileData />}
                        />
                        <Route path="/Login" element={<Login />} />
                        <Route path="/" element={<UserSidebar />} />
                        <Route
                            path="/EditPassword"
                            element={<EditUserPassword />}
                        />
                        <Route path="/AddUser" element={<AddUser />} />

                        <Route path="/Notify" element={<Notification />} />
                        <Route
                            path="/CreateContest"
                            element={<CreateContest />}
                        />
                        <Route
                            path="/first"
                            element={<AnnounceWinnerScreen />}
                        />
                        <Route
                            path="/daistotcontest"
                            element={<DaistotContest />}
                        />
                        <Route path="/edituser" element={<EditUser />} />
                    </Route>
                </Route>
                <Route path="/Profilesidebar" element={<ProfileCard />} />
                <Route
                    path="/eyeviewpostscreen"
                    element={<EyeViewPostScreen />}
                />
                <Route
                    path="/eyeviewContestscreen"
                    element={<EyeViewContestScreen />}
                />
                <Route path="/Win" element={<Winner />} />
                <Route path="/Winner" element={<ContextWinner />} />
                <Route path="/Select" element={<SelectWinnerModal />} />
                <Route path="/Submission" element={<SubmissionScreen />} />
                <Route path="/postmenubar" element={<PostMenuTab />} />
                <Route path="/Contestmenubar" element={<ContestMenuTab />} />
                <Route path="/selectwinner" element={<SelectWinners />} />
                <Route path="/Varify" element={<UserVarification />} />
            </Routes>
            <Sidebar />
        </>
    );
}

export default AppRouter;
