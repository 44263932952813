import React from "react";
import styled from "styled-components";

function RejectRegistration() {
    return (
        <>
            <Overlay></Overlay>
            <MainContainer>
                <ContentContainer>
                    <textarea
                        className="reason_for_removal"
                        rows="4"
                        cols="50"
                        placeholder="Enter the reason for rejection"
                    ></textarea>
                    <Buttons>
                        <Button>cancel</Button>
                        <Button className="Remove">REJECT</Button>
                    </Buttons>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default RejectRegistration;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1;
    display: block;
    &.active {
        display: block;
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 2;
    transform: scale(1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &.active {
        transform: scale(1);
    }
`;

const ContentContainer = styled.div`
    width: 35%;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    padding: 10px 10px;
    text-align: center;
    position: relative;
    textarea {
        background: gradient(90deg, #f4f4f4 0%, #f4f8fd 100%);
    }
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        font-size: 14px;
        margin-top: 10px;
        /* @media all and (max-width: 1440px) {
            font-size: 13px;
        } */
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    width: 20%;
`;
const CloseContainer = styled.div`
    width: 5%;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: #000;
`;
const Button = styled.div`
    cursor: pointer;
    font-size: 14px;
    padding: 10px 10px;
    @media all and (max-width: 1440px) {
        font-size: 13px;
    }
    &.Remove {
        margin-left: 20px;
        background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        height: 35px;
        color: #fff;
        align-items: center;
        width: 80px;
    }
`;
