import React from "react";
import { useState } from "react";
import styled from "styled-components";
import BackGround from "../../../../assets/images/dropdown.svg";
import ContextCreateSuccess from "./modal/ContextCreateSuccess";

function CreateContest() {
    const [isactive,setActive] = useState(false)
    return (
        <>
            <ContextCreateSuccess isactive={isactive} setActive={setActive}/>
            <MainConatiner id="center">
                <Header>Create a Contest</Header>
                <Body>
                    <Top>
                        <p>Contest Type</p>
                        <Type>
                            <Container>Inter-Campus</Container>
                            <Container>Intra-Campus</Container>
                        </Type>
                    </Top>
                    <ContestContain>
                        <FromContainer>
                            <Input>
                                <label>
                                    Contest name*
                                    <input type="text" name="name" required />
                                </label>
                                <label className="Upload">
                                    Cover Picture
                                    <input type="Number" name="name" />
                                </label>
                                <Upload>
                                    <input type="file" />
                                    Upload
                                </Upload>
                            </Input>
                            <Input>
                                <label>
                                    Contest Category*
                                    <select
                                        id="class"
                                        name="classlist"
                                        form="classform"
                                        required
                                    >
                                        <option>Select category</option>
                                        <option>Volvo</option>
                                        <option>Saab</option>
                                        <option>Opel</option>
                                        <option>Audi</option>
                                    </select>
                                </label>
                                <label>
                                    Division
                                    <select className="Sub">
                                        <option>Select Sub-category</option>
                                        <option>A</option>
                                        <option>B</option>
                                        <option>c</option>
                                        <option>D</option>
                                    </select>
                                </label>
                            </Input>
                            <Input>
                                <label>
                                    Starting Date*
                                    <input type="date" name="name" />
                                </label>
                                <label>
                                    Starting Time*
                                    <input type="time" name="name" />
                                </label>
                            </Input>
                            <Input>
                                <label>
                                    Ending Date*
                                    <input type="date" name="name" />
                                </label>
                                <label>
                                    Ending Time*
                                    <input type="time" name="name" />
                                </label>
                            </Input>
                            <Input>
                                <label>
                                    Last Date to Upload*
                                    <input type="date" name="name" />
                                </label>
                                <label>
                                    Winner Announcing Date*
                                    <input type="date" name="name" />
                                </label>
                            </Input>
                            <Input>
                                <label className="Guidelines">
                                    Contest Guidelines
                                    <input
                                        className="Guidelines"
                                        type="text"
                                        name="name"
                                    />
                                </label>
                            </Input>
                            <p>Prize Details</p>
                            <Input>
                                <label>
                                    Winner Position
                                    <select required>
                                        <option>Select Position</option>
                                        <option>Volvo</option>
                                        <option>Saab</option>
                                        <option>Opel</option>
                                        <option>Audi</option>
                                    </select>
                                </label>
                                <label>
                                    Winning Count
                                    <select>
                                        <option></option>
                                        <option>A</option>
                                        <option>B</option>
                                        <option>c</option>
                                        <option>D</option>
                                    </select>
                                </label>
                            </Input>
                            <Input className="Price">
                                <label>
                                    Prize
                                    <input type="text" name="name" required />
                                </label>
                                <label>
                                    Price Image
                                    <input type="Number" name="name" />
                                </label>
                                <Upload className="Price">
                                    <input type="file" />
                                    Choose file
                                </Upload>
                            </Input>
                            <AddPosition>+ Add Position</AddPosition>
                        </FromContainer>
                        <Button onClick={()=>setActive(true)}>Create</Button>
                    </ContestContain>
                </Body>
            </MainConatiner>
        </>
    );
}

export default CreateContest;

const MainConatiner = styled.div`
    padding: 20px 0px 8px 25px; 
`;
const Header = styled.h3`
    font-size: 22px;
    margin-bottom: 30px;
    color: black;
    font-family: "poppins-regular";
`;
const Body = styled.div``;
const Top = styled.div`
    p {
        font-size: 16px;
        margin-bottom: 10px;
    }
`;
const Type = styled.div`
    display: flex;
`;
const Container = styled.div`
    font-size: 14px;
    display: flex;
    width: 130px;
    height: 35px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: black;
    border: 0.1px solid #c3c3c3;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 10px;
    &:first-child {
        margin-left: 0;
    }
    &.active {
        background: rgba(3, 180, 245, 0.2);
        color: #4287f0;
    }
`;
const ContestContain = styled.div``;
const FromContainer = styled.div`
    width: 90%;
    margin-top: 40px;
    p {
        font-family: "poppins-semibold";
        color: black;
        font-size: 14px;
        margin-bottom: 20px;
    }
    h4 {
        font-size: 14px;
        font-family: "poppins-semibold";
        color: black;
        margin-bottom: 20px;
    }
`;
const Input = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 30px;

    &.Price {
        position: relative;
        margin-bottom: 15px;
    }
    label {
        font-size: 14px;
        color: grey;
        width: 50%;
        &.Password {
            position: relative;
        }
        &.Upload {
            position: relative;
        }
        &.Price {
            position: relative;
        }
        &.Guidelines {
            width: 100%;
        }
    }
    input {
        width: 90%;
        display: flex;
        border-bottom: 1px solid grey;
        margin-top: 5px;
        align-items: flex-end;
        &.Guidelines {
            width: 95%;
            margin-top: 5px;
        }
    }
    select {
        width: 90%;
        display: flex;
        border-bottom: 1px solid grey;
        margin-top: 10px;
        background-image: url(${BackGround});
        background-repeat: no-repeat;
        background-position: right;
        background-size: 10px;
        font-size: 14px;
        &.Sub {
            border-bottom: 0px;
        }
    }
`;
const Upload = styled.div`
    background: #d2e1fb;
    color: #202020;
    border-radius: 5px;
    cursor: pointer;
    padding: 6px 16px;
    position: absolute;
    right: 13%;
    bottom: 59%;

    &.Price {
        right: 5%;
        bottom: 5px;
    }
    input {
        position: absolute;
        cursor: pointer;
        width: 100%;
        padding: 5px;
        top: 0;
        left: 0;
        opacity: 0;
        width: 90%;
        display: flex;
        border-bottom: 1px solid grey;
        margin-top: 5px;
        align-items: flex-end;
    }
    @media all and (max-width: 1440px) {
        font-size: 13px;
    }
`;
const AddPosition = styled.div`
    font-size: 12px;
    display: flex;
    width: 110px;
    height: 25px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    color: #4287f0;
    background: rgba(3, 180, 245, 0.2);
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
`;
const Button = styled.div`
    height: 40px;
    width: 15%;
    font-size: 14px;
    color: #fff;
    display: flex;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
`;
