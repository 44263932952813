import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SelectExcelFile from "./Modals/SelectExcelFile";

function AddStudent({ isdata, setData }) {
    const [iscontact, setContact] = useState(false);
    return (
        <>
            <SelectExcelFile setContact={setContact} iscontact={iscontact} />
            <Overlay className={isdata ? "active" : ""}></Overlay>
            <MainContainer className={isdata ? "active" : ""}>
                <Cards>
                    <DocContainer
                        onClick={() => {
                            setContact(!iscontact);
                            setData(false);
                            console.log("hi");
                        }}
                    >
                        <ImageDiv className="doc">
                            <img
                                src={
                                    require("../../../../assets/images/Upload-data.svg")
                                        .default
                                }
                                alt="Upload-data"
                            />
                        </ImageDiv>
                        <p>Import students data</p>
                    </DocContainer>
                    <ContentContainer to="/AddUser">
                        <ImageDiv>
                            <img
                                src={
                                    require("../../../../assets/images/add-student.svg")
                                        .default
                                }
                                alt="Upload-data"
                            />
                        </ImageDiv>
                        <p>Add student </p>
                    </ContentContainer>
                    <CloseContainer onClick={() => setData(false)}>
                        <img
                            src={
                                require("../../../../assets/images/Close-icon.svg")
                                    .default
                            }
                            alt=""
                        />
                    </CloseContainer>
                </Cards>
            </MainContainer>
        </>
    );
}

export default AddStudent;
const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 501;
    display: none;
    &.active {
        display: block;
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 502;
    transform: scale(0);
    transition: 0.3s ease-in-out;
    &.active {
        transform: scale(1);
    }
`;
const Cards = styled.div`
    width: 46%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 980px) {
        width: 60%;
    }
    @media all and (max-width: 768px) {
        width: 75%;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const DocContainer = styled.div`
    width: 45%;
    height: 35vh;
    border-radius: 8px;
    background: #fff;
    color: black;
    text-align: center;
    cursor: pointer;
    padding: 40px 15px 30px 15px;
    position: relative;
    margin-right: 20px;

    p {
        font-family: "poppins-semiBold";
        font-size: 16px;
        margin-top: 50px;
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
`;
const ContentContainer = styled(Link)`
    width: 45%;
    height: 35vh;
    border-radius: 8px;
    background: #fff;
    color: black;
    text-align: center;
    cursor: pointer;
    padding: 40px 15px 30px 15px;
    position: relative;
    input {
        cursor: pointer;
        width: 100%;
        top: 0;
        left: 0;
        height: 35vh;
        position: absolute;
        opacity: 0;
    }
    &.doc {
        margin-right: 20px;
        @media all and (max-width: 480px) {
            margin-bottom: 20px;
            margin-right: 0px;
        }
    }
    @media all and (max-width: 980px) {
        width: 40%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        font-family: "poppins-semiBold";
        font-size: 16px;
        margin-top: 50px;
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
`;
const ImageDiv = styled.div`
    margin: 0 auto;
    width: 30%;
    color: #000;
    &.doc {
        width: 23%;
    }
`;
const CloseContainer = styled.div`
    width: 3%;
    position: absolute;
    bottom: 21%;
    cursor: pointer;
    @media all and (max-width: 980px) {
        width: 4%;
    }
    @media all and (max-width: 768px) {
        width: 5%;
    }
    @media all and (max-width: 480px) {
        bottom: 5%;
        width: 7%;
    }
    img {
        display: block;
        width: 100%;
    }
`;
