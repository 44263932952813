import './App.css';
import '../src/assets/css/style.css'
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './components/routing/routers/MainRouter';
import { createContext, useContext, useState } from 'react';

export const ProgramContext = createContext()

function App() {
  const [programCategory,setProgramCategory] = useState("")
  return (
    <>
    <ProgramContext.Provider value={{programCategory,setProgramCategory}} >
     <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
      </ProgramContext.Provider>
    </>
  );
}

export default App;
