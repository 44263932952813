import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ProfileBackground from "../../../../assets/images/profilescreenBackground.svg";

export default function ProfileCard() {
    return (
        <ProfileCardContainer>
            <TopBanner>
                <TopBanneImage>
                    <img
                        src={
                            require("../../../../assets/images/profilescreenBackground.svg")
                                .default
                        }
                        alt="background"
                    />
                </TopBanneImage>
                <IconDiv>
                    <LeftArrow to="/user">
                        <img
                            src={
                                require("../../../../assets/images/arrow-left-black.svg")
                                    .default
                            }
                            alt="leftarrow"
                        />
                    </LeftArrow>
                    <Rightdot>
                        <img
                            src={
                                require("../../../../assets/images/more-horizontal-black.svg")
                                    .default
                            }
                            alt="moreicon"
                        />
                    </Rightdot>
                </IconDiv>
                <ProfilePictureDiv>
                    <img
                        src={
                            require("../../../../assets/images/profileSquare.svg")
                                .default
                        }
                        alt="ProfileSquare"
                    />
                </ProfilePictureDiv>
            </TopBanner>
            <BottomContainer>
                <ProfileContainer>
                    <NameDiv>
                        <Name>Jhon Dennis</Name>
                        <Veri>
                            <img
                                src={
                                    require("../../../../assets/images/verification.svg")
                                        .default
                                }
                                alt="verification-icon"
                            />
                        </Veri>
                    </NameDiv>
                </ProfileContainer>
                <PostCountDiv>
                    <CountDiv>
                        <CountNumber>24</CountNumber>
                        <CountName>POSTS</CountName>
                    </CountDiv>
                    <CountDiv>
                        <CountNumber>123</CountNumber>
                        <CountName>FOLLOWERS</CountName>
                    </CountDiv>
                    <CountDiv>
                        <CountNumber>178</CountNumber>
                        <CountName>FOLLOWING</CountName>
                    </CountDiv>
                </PostCountDiv>
                <Wrapperdiv>
                    <DetailDiv>
                        <Item>
                            <Icon>
                                <img
                                    src={
                                        require("../../../../assets/images/class.svg")
                                            .default
                                    }
                                    alt="classicon"
                                />
                            </Icon>
                            <DetailName>1st Year</DetailName>
                        </Item>
                        <Item>
                            <Icon>
                                <img
                                    src={
                                        require("../../../../assets/images/Phone.svg")
                                            .default
                                    }
                                    alt="classicon"
                                />
                            </Icon>
                            <DetailName>+91 9526907757</DetailName>
                        </Item>
                        <Item>
                            <Icon>
                                <img
                                    src={
                                        require("../../../../assets/images/mail.svg")
                                            .default
                                    }
                                    alt="classicon"
                                />
                            </Icon>
                            <DetailName>amxxx@gmail.com</DetailName>
                        </Item>
                        <Item>
                            <Icon>
                                <img
                                    src={
                                        require("../../../../assets/images/map-pin.svg")
                                            .default
                                    }
                                    alt="classicon"
                                />
                            </Icon>
                            <DetailName>Kalamassery,kochi</DetailName>
                        </Item>
                    </DetailDiv>
                    <Overalldiv>
                        <OverallHead>
                            <Tittle>View Overall Insights</Tittle>
                            <DownArrowIcon>
                                <img
                                    src={
                                        require("../../../../assets/images/down-arrow.svg")
                                            .default
                                    }
                                    alt="downarrow"
                                />
                            </DownArrowIcon>
                        </OverallHead>
                        <InsightsDiv>
                            <Insights>
                                <InsightIcon>
                                    <img
                                        src={
                                            require("../../../../assets/images/Contest_Icon.svg")
                                                .default
                                        }
                                        alt="contest"
                                    />
                                </InsightIcon>
                                <InsightName>
                                    17 Contests participated
                                </InsightName>
                            </Insights>
                            <Insights>
                                <InsightIcon>
                                    <img
                                        src={
                                            require("../../../../assets/images/heart-1.svg")
                                                .default
                                        }
                                        alt="heart"
                                    />
                                </InsightIcon>
                                <InsightName>583 Total likes</InsightName>
                            </Insights>
                            <Insights>
                                <InsightIcon>
                                    <img
                                        src={
                                            require("../../../../assets/images/Contest_Icon.svg")
                                                .default
                                        }
                                        alt="contest"
                                    />
                                </InsightIcon>
                                <InsightName>5 Winning Contests</InsightName>
                            </Insights>
                            <Insights>
                                <InsightIcon>
                                    <img
                                        src={
                                            require("../../../../assets/images/uiw_like-o.svg")
                                                .default
                                        }
                                        alt="contest"
                                    />
                                </InsightIcon>
                                <InsightName>178 post liked</InsightName>
                            </Insights>
                        </InsightsDiv>
                    </Overalldiv>
                </Wrapperdiv>
            </BottomContainer>
        </ProfileCardContainer>
    );
}

const ProfileCardContainer = styled.div`
    width: 350px;
    margin-left: 280px;
    position: fixed;
`;
const TopBanner = styled.div`
    position: relative;
    margin-bottom: 44px;
`;
const IconDiv = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 11px;
    width: 89%;
    left: 12px;
`;
const LeftArrow = styled(Link)`
    width: 20px;
`;
const Rightdot = styled.div`
    width: 20px;
`;
const TopBanneImage = styled.div``;
const ProfilePictureDiv = styled.div`
    width: 68px;
    position: absolute;
    top: 135px;
    right: 121px;
`;
const BottomContainer = styled.div`
    width: 100%;
`;
const ProfileContainer = styled.div`
    margin-bottom: 10px;
    width: 80%;
    margin: 0 auto 20px;
`;
const NameDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Name = styled.div`
    font-size: 15px;
`;
const Veri = styled.div`
    width: 15px;
    margin-left: 5px;
`;
const PostCountDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto 20px;
`;
const CountDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const CountNumber = styled.div`
    font-size: 14px;
    font-family: "poppins-semibold";
    color: #000;
`;
const CountName = styled.div`
    font-size: 11px;
`;
const Item = styled.div`
    display: flex;
    margin-left: 20px;
    margin-bottom: 10px;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Icon = styled.div`
    width: 15px;
`;
const DetailName = styled.span`
    font-size: 12px;
    margin-left: 10px;
    font-family: "poppins-semibold";
`;
const DetailDiv = styled.div`
    background: linear-gradient(90deg, #d7f8ff 0%, #e2f8ff 100%);
    padding: 10px 0;
    border-radius: 5px;
    justify-content: center;
    margin-bottom: 10px;
`;
const Overalldiv = styled.div``;
const OverallHead = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;
const Tittle = styled.span`
    font-family: "poppins-semibold";
    font-size: 12px;
`;
const DownArrowIcon = styled.div`
    width: 20px;
    margin-left: 10px;
`;
const Wrapperdiv = styled.div`
    width: 95%;
    margin: 0 auto;
`;
const InsightsDiv = styled.div``;
const Insights = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const InsightIcon = styled.div`
    width: 16px;
    margin-right: 10px;
`;
const InsightName = styled.span`
    font-size: 13px;
`;
