import React, { useState } from "react";
import styled from "styled-components";

function EditProfileData({ isedit, setEdit, ispassword, setPassword }) {
    const [issuccess, setSuccess] = useState(false);
    return (
        <>
            <Overlay
                onClick={() => setEdit(false)}
                className={isedit ? "active" : ""}
            ></Overlay>
            <Conatainer className={isedit ? "active" : ""}>
                <TopContainer>
                    <ImageConatiner>
                        <img
                            src={
                                require("../../../../assets/images/SchoolDemo.svg")
                                    .default
                            }
                            alt="X"
                        />
                    </ImageConatiner>
                    <EditContainer>
                        <img
                            src={
                                require("../../../../assets/images/Edit Profile.svg")
                                    .default
                            }
                            alt="Edit"
                        />
                    </EditContainer>
                </TopContainer>
                <MiddleContainer>
                    <Input>
                        <label>User Name</label>
                        <p>
                            <input type="text" name="name" />
                        </p>
                    </Input>
                    <Input>
                        <label>Email ID</label>
                        <p>
                            <input type="email" name="name" />
                        </p>
                    </Input>
                    <Input>
                        <label>Phone No</label>
                        <p>
                            <input type="number" name="name" />
                        </p>
                    </Input>
                    <Input>
                        <label>Location</label>
                        <p>
                            <input type="Text" name="name" />
                        </p>
                    </Input>
                </MiddleContainer>
                <BottomContainer>
                    <Button
                        onClick={() => {
                            setSuccess(true);
                            setPassword(false);
                        }}
                    >
                        SAVE
                    </Button>
                </BottomContainer>
                <CloseContainer onClick={() => setEdit(false)}>
                    <img
                        src={require("../../../../assets/images/x.svg").default}
                        alt="X"
                    />
                </CloseContainer>
            </Conatainer>
        </>
    );
}

export default EditProfileData;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 501;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;
const Conatainer = styled.section`
    width: 350px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 502;
    background: rgb(255, 255, 255);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 15px 20px;
    &.active {
        right: 0;
    }
`;
const TopContainer = styled.div`
    margin-top: 30px;
`;
const EditContainer = styled.div`
    position: absolute;
    top: 22%;
    right: 39%;
    width: 22px;
    cursor: pointer;
    img {
        display: block;
        width: 65%;
    }
`;
const ImageConatiner = styled.div`
    width: 75%;
    margin: 0 auto;
    position: relative;
`;
const MiddleContainer = styled.div`
    width: 100%;
`;
const Input = styled.div`
    margin-bottom: 15px;
    label {
        font-size: 14px;
    }
    p {
        border-bottom: 1px solid #e7e2e2;
        input {
            font-size: 14px;
            width: 100%;
        }
    }
`;
const BottomContainer = styled.div`
    width: 100%;
`;
const Button = styled.div`
    font-size: 18px;
    font-family: "poppins-regular";
    color: #fff;
    display: flex;
    margin: 0 auto;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    cursor: pointer;
`;
const CloseContainer = styled.div`
    width: 6%;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
`;
