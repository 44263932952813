import React from "react";
import { useState } from "react";
import styled from "styled-components";
import EditProfileData from '../../Edituser/EditProfileData'
import EditUserPassword from "../../Edituser/EditUserPassword";
function UserSidebar({ isuser, setUser }) {
    const [isedit,setEdit] = useState(false)
    const [ispassword,setPassword] = useState(false)
    return (
        <>
            <EditUserPassword ispassword={ispassword} setPassword={setPassword}  />
            <EditProfileData isedit={isedit} setEdit={setEdit}  />
            <Overlay
                onClick={() => setUser(false)}
                className={isuser ? "active" : ""}
            ></Overlay>
            <Conatainer className={isuser ? "active" : ""}>
                <TopContainer>
                    <ImageConatiner>
                        <img
                            src={
                                require("../../../../../assets/images/SchoolDemo.svg")
                                    .default
                            }
                            alt="X"
                        />
                    </ImageConatiner>
                    <Header>ABC Higher Secondary School</Header>
                    <MiddleContainer>
                        <Number>
                            <IconDiv>
                                <img
                                    src={
                                        require("../../../../../assets/images/Phone icon.svg")
                                            .default
                                    }
                                    alt="Phone"
                                />
                            </IconDiv>
                            <p>1234567890</p>
                        </Number>
                        <Email>
                            <IconDiv>
                                <img
                                    src={
                                        require("../../../../../assets/images/mail.svg")
                                            .default
                                    }
                                    alt="Mail"
                                />
                            </IconDiv>
                            <p>amxxxx@email.com</p>
                        </Email>
                        <Location>
                            <IconDiv>
                                <img
                                    src={
                                        require("../../../../../assets/images/map-pin.svg")
                                            .default
                                    }
                                    alt="Map"
                                />
                            </IconDiv>
                            <p>Kochi,Kerala</p>
                        </Location>
                    </MiddleContainer>
                </TopContainer>
                <BottomContainer>
                    <EditProfile onClick={()=>{
                        setUser(false)
                        setEdit(true)   
                    }}>EDIT PROFILE</EditProfile>
                    <Button onClick={()=>{
                        setPassword(true)
                        setUser(false)
                    }}>CHANGE PASSWORD</Button>
                </BottomContainer>
                <CloseContainer onClick={() => setUser(false)}>
                    <img
                        src={
                            require("../../../../../assets/images/x.svg")
                                .default
                        }
                        alt="X"
                    />
                </CloseContainer>
            </Conatainer>
        </>
    );
}

export default UserSidebar;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 501;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;
const Conatainer = styled.section`
    width: 350px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 502;
    background: rgb(255, 255, 255);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    &.active {
        right: 0;
    }
`;
const TopContainer = styled.div`
    width: 100%;
    padding-top: 30px;
`;
const Header = styled.p`
    font-size: 18px;
    font-family: "poppins-medium";
    margin-top: 20px;
    text-align: center;
`;
const ImageConatiner = styled.div`
    width: 40%;
    margin: 0 auto;
    position: relative;
`;

const MiddleContainer = styled.div`
    background: linear-gradient(90deg, #D7F8FF 0%, #E2F8FF 100%);
    padding: 10px 15px;
    margin-top: 30px;
    border-radius: 5px;
`;
const Number = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        font-size: 18px;
        font-family: "poppins-regular";
        color: #000;
    }
`;
const IconDiv = styled.div`
    width: 15px;
    margin-right: 15px;
`;
const Email = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        font-size: 18px;
        font-family: "poppins-regular";
        color: #000;
    }
`;
const Location = styled.div`
    display: flex;
    align-items: flex-start;
    p {
        font-size: 18px;
        font-family: "poppins-regular";
        color: #000;
    }
`;

const BottomContainer = styled.div`
    width: 100%;
`;
const EditProfile = styled.div`
    font-size: 18px;
    color: #17a6f3;
    display: flex;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    border: 1px solid #17a6f3;
    margin-bottom: 10px;
    cursor: pointer;
`;
const Button = styled.div`
    font-size: 18px;
    color: #fff;
    display: flex;
    margin: 0 auto;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    cursor: pointer;
`;
const CloseContainer = styled.div`
    width: 6%;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
`;
