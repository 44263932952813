import React from "react";
import styled from "styled-components";

function UserVarification() {
    return (
        <>
            <MainContainer id="centre">
                <Header>
                    <h4>Pending Verification</h4>
                </Header>
                <CardsContainer>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <AvatarImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </AvatarImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>+91<span>1234567890</span></p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <AvatarImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </AvatarImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>+91<span>1234567890</span></p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <AvatarImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </AvatarImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>+91<span>1234567890</span></p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <AvatarImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </AvatarImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>+91<span>1234567890</span></p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <AvatarImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </AvatarImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>+91<span>1234567890</span></p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <AvatarImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </AvatarImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>+91<span>1234567890</span></p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <ImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </ImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>1234567890</p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <ImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </ImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>1234567890</p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                    <Cards>
                        <CardLeft>
                            <TopDiv>
                                <ImageContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/userIcon.svg")
                                                .default
                                        }
                                        alt="User"
                                    />
                                </ImageContainer>
                                <Name>Jhon Dennis</Name>
                            </TopDiv>
                            <MiddleDiv>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/cap.svg")
                                                    .default
                                            }
                                            alt="Cap"
                                        />
                                    </IconDiv>
                                    <p>1 St yr</p>
                                </Number>
                                <Number>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/Phone icon.svg")
                                                    .default
                                            }
                                            alt="Phone"
                                        />
                                    </IconDiv>
                                    <p>1234567890</p>
                                </Number>
                                <Email>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/mail.svg")
                                                    .default
                                            }
                                            alt="Mail"
                                        />
                                    </IconDiv>
                                    <p>amxxxx@email.com</p>
                                </Email>
                                <Location>
                                    <IconDiv>
                                        <img
                                            src={
                                                require("../../../assets/images/map-pin.svg")
                                                    .default
                                            }
                                            alt="Map"
                                        />
                                    </IconDiv>
                                    <p>Kochi, Kerala</p>
                                </Location>
                            </MiddleDiv>
                            <BottomDiv>
                                <CloseDiv>
                                    <img
                                        src={
                                            require("../../../assets/images/TickBgGreen.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                                <CloseDiv className="Close">
                                    <img
                                        src={
                                            require("../../../assets/images/CloseBgRed.svg")
                                                .default
                                        }
                                        alt="TickGreen"
                                    />
                                </CloseDiv>
                            </BottomDiv>
                        </CardLeft>
                        <CardRight>
                            <Head>ID Card</Head>
                            <IdCardContainer>
                                <img
                                    src={
                                        require("../../../assets/images/IdCard.svg")
                                            .default
                                    }
                                    alt="Idcard
                        "
                                />
                            </IdCardContainer>
                        </CardRight>
                    </Cards>
                </CardsContainer>
            </MainContainer>
        </>
    );
}

export default UserVarification;

const MainContainer = styled.div`
    width: 80%;
    height: 100vh;
    margin-top: 0 !important;
    margin-left: 280px !important;
    @media all and (max-width: 980px){
        margin-left: 0 !important;
    }
`;
const Header = styled.div`
    text-align: center;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    width: 100%;
    h4 {
        font-size: 20px;
        color: #000;
        padding: 15px;
    }
`;
const CardsContainer = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 25px;
`;
const Cards = styled.li`
    /* width: 30%; */
    display: flex;
`;
const CardLeft = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 350px;
    border-radius: 10px 0px 0px 10px;
    padding: 35px 12px 19px;
    justify-content: space-between;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    p {
        font-size: 12px;
    }
`;
const AvatarImageContainer = styled.div`
    width: 30%;     
`;
const ImageContainer = styled.div`
    width: 30%;
`;
const Name = styled.div`
    font-size: 16px;
    font-family: "poppins-regular";
    color: #000;
    margin-left: 10px;
`;
const CardRight = styled.div`
    background: #b9c8da;
    width: 500px;
    height: 350px;
    border-radius: 0px 10px 10px 0px;
`;
const MiddleDiv = styled.div`
    padding-left: 15px;
`;
const Number = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        font-size: 14px;
        color: #000;
        display: flex;
        
    }
    span {
        font-size: 14px;
        color: #000;
        margin-left: 5px;
    }
`;
const Email = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        font-size: 14px;
        color: #000;
    }
`;
const Location = styled.div`
    display: flex;
    align-items: centre;
    p {
        font-size: 14px;
        color: #000;
    }
`;
const IconDiv = styled.div`
    width: 15px;
    margin-right: 10px;
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: space-around;
`;
const Head = styled.div`
    font-size: 16px;
    font-family: "poppins-regular";
    color: #000;
    padding: 10px;
`;
const CloseDiv = styled.div`
    width: 30%;
    cursor: pointer;
    /* &.Close{
        margin-left: 10px;
    } */
`;
const IdCardContainer = styled.div`
    width: 80%;
    margin: 0px auto;
    height: 300px;
    display: flex;
`;
