import React from "react";
import styled from "styled-components";

function PasswordChangeSuccess({ issuccess, setSuccess }) {
    return (
        <>
            <Overlay className={issuccess ? "active" : ""}></Overlay>
            <MainContainer
                className={issuccess ? "active" : ""}
                
            >
                <ContentContainer>
                    <ImageContainer>
                        <img
                            src={
                                require("../../../../assets/images/Succes.svg")
                                    .default
                            }
                            alt="Succes"
                        />
                    </ImageContainer>
                    <p>Password changed succesfully</p>
                    <CloseContainer onClick={() => setSuccess(false)}>
                        <img
                            src={
                                require("../../../../assets/images/x.svg")
                                    .default
                            }
                            alt="X"
                        />
                    </CloseContainer>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default PasswordChangeSuccess;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    position: fixed;
    z-index: 503;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 504;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;

const ContentContainer = styled.div`
    width: 25%;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    padding: 40px 15px;
    text-align: center;
    position: relative;
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        font-size: 14px;
        margin-top: 10px;
        /* @media all and (max-width: 1440px) {
            font-size: 13px;
        } */
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    width: 20%;
`;
const CloseContainer = styled.div`
    width: 5%;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
`;
