import React from "react";
import styled from "styled-components";
import BackGround from "../../../../../assets/images/dropdown.svg";

function SelectWinnerModal({ isWinner, setWinner }) {
    return (
        <>
            <Overlay className={isWinner ? "active" : ""}></Overlay>
            <MainContainer className={isWinner ? "active" : ""}>
                <ContentContainer>
                    <TopContainer>
                        <Header>Select Winner</Header>
                        <CloseContainer onClick={() => setWinner(false)}>
                            <img
                                src={
                                    require("../../../../../assets/images/x.svg")
                                        .default
                                }
                                alt="X"
                            />
                        </CloseContainer>
                    </TopContainer>
                    <BottomContainer>
                        <Input>
                            <select>
                                <option value="volvo">1st</option>
                                <option value="volvo">2nd</option>
                                <option value="saab">3rd</option>
                            </select>
                            <select>
                                <option value="0">
                                    Select winning criteria
                                </option>
                                <option value="1">A</option>
                                <option value="2">B</option>
                                <option value="3">c</option>
                            </select>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter Ponits"
                            />
                        </Input>
                    </BottomContainer>
                    <ButtonContainer>
                        <Button>SELECT</Button>
                    </ButtonContainer>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default SelectWinnerModal;
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 503;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 504;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;

const ContentContainer = styled.div`
    width: 25%;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        font-size: 14px;
        margin-top: 10px;
    }
`;

const CloseContainer = styled.div`
    cursor: pointer;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
`;
const Header = styled.h4`
    font-size: 18px;
    color: #000;
    font-family: "poppins-semibold";
`;
const BottomContainer = styled.div``;
const Input = styled.div`
    width: 100%;
    margin-bottom: 30px;

    select {
        width: 100%;
        display: flex;
        border: 1px solid #4684ef;
        border-radius: 8px;
        margin-bottom: 25px;
        background-image: url(${BackGround});
        background-repeat: no-repeat;
        background-position: right 16px top 17px;
        background-size: 10px;
        font-size: 14px;
        font-family: "poppins-semibold";
        padding: 10px;
        cursor: pointer;
        align-items: flex-end;
    }
    input {
        width: 100%;
        display: flex;
        border: 1px solid #4684ef;
        margin-top: 10px;
        font-size: 14px;
        padding: 10px;
        font-family: "poppins-semibold";
    }
    &.Upload {
        position: relative;
    }
`;
const Button = styled.div`
    height: 40px;
    width: 25%;
    font-size: 14px;
    color: #fff;
    display: flex;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'poppins-regular';
    letter-spacing: .04cm;
`;
