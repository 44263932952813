import React from "react";
import { useState } from "react";
import styled from "styled-components";
import AddStudent from "../../includes/User/adduser/AddStudent";
import SelectExcelFile from "../../includes/User/adduser/Modals/SelectExcelFile";
import UserAdded from "../../includes/User/adduser/Modals/UserAdded";
import RemoveUser from "../../includes/User/removeUser/RemoveUser";
import Pagination from "../../includes/User/userscreen/Pagination";
import List from "../../includes/User/userscreen/userincludes/List";

export default function UserScreen() {
    const [isdata, setData] = useState(false);
    const [iscontact, setContact] = useState(false);
    const [isdelete, setDelete] = useState(false);

    const [datas, setDatas] = useState([
        {
            sl: "001",
            name: "name 01",
            email: "ajmal@gmail.com",
            phone: "9526907757",
        },
        {
            sl: "002",
            name: "name 02",
            email: "ajmal@gmail.com",
            phone: "9837654589",
        },
        {
            sl: "003",
            name: "name 03",
            email: "ajmal@gmail.com",
            phone: "6789065443",
        },
        {
            sl: "004",
            name: "name 04",
            phone: "2345687641",
            email: "ajmal@gmail.com",
        },
        {
            sl: "005",
            name: "name 05",
            email: "ajmal@gmail.com",
            phone: "9526907757",
        },
        {
            sl: "006",
            name: "name 06",
            email: "ajmal@gmail.com",
            phone: "9526907757",
        },
        {
            sl: "007",
            name: "name 07",
            email: "ajmal@gmail.com",
            phone: "9526907757",
        },
        {
            sl: "008",
            name: "name 08",
            email: "ajmal@gmail.com",
            phone: "9526907757",
        },
        {
            sl: "009",
            name: "name 09",
            email: "ajmal@gmail.com",
            phone: "9526907757",
        },
    ]);
    const [currentpage, setCurrentpage] = useState(1);
    const [itemsperpages, setItemsperpage] = useState(6);
    let indexOflastitem = currentpage * itemsperpages;
    let indexOffristitem = indexOflastitem - itemsperpages;
    let showitem = datas.slice(indexOffristitem, indexOflastitem);

    let numberofpages = [];
    for (let i = 1; i <= datas.length / itemsperpages; i++) {
        console.log(i);
        numberofpages.push(i);
    }
    const incNum = () => {
        setCurrentpage(currentpage + 1);
    };
    const decNum = () => {
        setCurrentpage(currentpage - 1);
    };

    return (
        <>
            <AddStudent isdata={isdata} setData={setData} />

            <RemoveUser isdelete={isdelete} setDelete={setDelete} />
            <UserScreenContainer id="center">
                <TopPortion>
                    <Head>
                        <Title>Users</Title>
                        <RightContainer>
                            <Result>Show results</Result>
                            <AddUserButton onClick={() => setData(!isdata)}>
                                + Add User
                            </AddUserButton>
                            <FilterButton>
                                <FilterIcon>
                                    <img
                                        src={
                                            require("../../../assets/images/Iconly.svg")
                                                .default
                                        }
                                        alt="iconly"
                                    />
                                </FilterIcon>
                                <Name>filter</Name>
                            </FilterButton>
                        </RightContainer>
                    </Head>
                    <CountDiv>
                        <SubHeadList>
                            <SubHead type="all">All Users</SubHead>
                            <SubHead type="user">Verified Users</SubHead>
                            <SubHead type="user">Registered Users</SubHead>
                        </SubHeadList>
                        <Counttext>Registered Users: 152</Counttext>
                    </CountDiv>
                </TopPortion>
                <ListContainer>
                    <TitleContainer>
                        <Titleitem type="sl">ID No.</Titleitem>
                        <Titleitem type="name">User Name</Titleitem>
                        <Titleitem type="name">Email</Titleitem>
                        <Titleitem type="name">Phone</Titleitem>
                        <Titleitem>Action</Titleitem>
                    </TitleContainer>
                </ListContainer>
                {showitem.map((item) => (
                    <List
                        key={item.sl}
                        data={item}
                        isdelete={isdelete}
                        setDelete={setDelete}
                    />
                ))}
                <Pagination
                    datas={datas}
                    currentpage={currentpage}
                    itemsperpages={itemsperpages}
                    setCurrentpage={setCurrentpage}
                    incNum={incNum}
                    decNum={decNum}
                    showitem={showitem}
                />
            </UserScreenContainer>
        </>
    );
}

const Counttext = styled.span`
    font-size: 14px;
    margin-bottom: 15px;
`;
const CountDiv = styled.div``;
const ListContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;
const TitleContainer = styled.div`
    display: flex;
`;
const Titleitem = styled.span`
    background: #4187f0;
    margin-right: 10px;
    color: #fff;
    padding: 17px 37px;
    font-size: 15px;
    align-items: center;
    border-radius: 6px;
    width: ${({ type }) =>
        type === "sl" ? "9%" : type == "name" ? "35%" : "21%"};
    &:first-child {
        padding: 17px 15px;
    }
    &:last-child {
        margin-right: 0;
    }
`;
const UserScreenContainer = styled.div`
    width: 80%;
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
`;
const TopPortion = styled.div``;
const LeftContainer = styled.div`
    width: 50%;
`;
const Title = styled.h4`
    font-family: "poppins-semibold";
    font-size: 25px;
    color: #000;
`;
const SubHeadList = styled.div`
    display: flex;
    background: #ffffff;
    border-radius: 14px;
    width: 45%;
    padding: 10px 11px;
    margin-bottom: 15px;
`;
const SubHead = styled.span`
    background: #ffffff;
    border-radius: 7px;
    width: ${({ type }) => (type === "all" ? "25%" : "35%")};
    text-align: center;
    margin: 0 auto;
    background: #ffffff;
    text-align: center;
    margin: 0 auto;
    padding: 4px 10px;
    font-size: 17px;
    color: #202020;
    font-family: "poppins-regular";
    line-height: 1.3;
    cursor: pointer;
    color: #202020;
    font-family: "poppins-regular";
    line-height: 1.3;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: #eff3f9;
    }
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 31%;
`;
const Result = styled.span`
    font-size: 18px;
    font-family: "poppins-regular";
`;
const AddUserButton = styled.div`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 4px;
    padding: 5px 15px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-family: "poppins-regular";
`;
const PlusIcon = styled.div``;
const ButtonName = styled.span``;
const FilterButton = styled.div`
    display: flex;
    background: #fff;
    border-radius: 4px;
    padding: 5px 15px;
    font-size: 12px;
    align-items: center;
    font-family: "poppins-regular";

    display: flex;
    background: #fff;
    border-radius: 4px;
    padding: 5px 14px;
    width: 31%;
    font-size: 12px;
    align-items: center;
    font-family: "poppins-regular";
`;
const FilterIcon = styled.div`
    width: 17px;
    margin-right: 10px;
`;
const Name = styled.span`
    font-size: 18px;
    font-family: "poppins-regular";
`;
