import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

import styled from "styled-components";

function Logout({ isLogout, setLogout }) {
    return (
        <>
            <Overlay className={isLogout ? "active" : ""}></Overlay>
            <MainContainer className={isLogout ? "active" : ""}>
                <ContentContainer>
                    <p>You will be returned to the login screen</p>
                    <Buttons>
                        <Button to="/" onClick={() => setLogout(!isLogout)}>
                            cancel
                        </Button>
                        <Button
                            to="/"
                            className="Remove"
                            onClick={() => setLogout(!isLogout)}
                        >
                            Log Out
                        </Button>
                    </Buttons>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default Logout;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transform: scale(0);
    display: none;
    &.active {
        transform: scale(1);
        display: block;
    }
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    top: 0%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100;
    transform: scale(0);
    transition: 0.3s ease-in-out;
    display: none;
    &.active {
        transform: scale(1);
        display: flex;
    }
`;

const ContentContainer = styled.div`
    width: 25%;
    border-radius: 8px;
    background: #fff;
    padding: 40px 15px 15px 15px;
    text-align: center;
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        width: 66%;
        margin: 0 auto;
        line-height: 1.5;

        @media all and (max-width: 1440px) {
            font-size: 13px;
        }
    }
`;
const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    color: #000;
`;
const Button = styled(Link)`
    cursor: pointer;
    font-size: 14px;
    color: #000;
    padding: 10px 10px;
    text-decoration: none;
    @media all and (max-width: 1440px) {
        font-size: 13px;
    }
    &.Remove {
        margin-left: 20px;
        background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
        border-radius: 12px;
        padding: 10px 10px;
        color: #fff;
        text-decoration: none;
    }
`;
