import React, { useState } from "react";
import Graph from "./Graph";
import styled from "styled-components";

function DashboardBottom() {
    const [listitems, setListitems] = useState([
        {
            id: 1,
            Division: "LKG",
            Percentage: "11%",
        },
        {
            id: 2,
            Division: "UKG",
            Percentage: "20%",
        },
        {
            id: 3,
            Division: "1st",
            Percentage: "40%",
        },
        {
            id: 4,
            Division: "2nd",
            Percentage: "50%",
        },
        {
            id: 5,
            Division: "3rd",
            Percentage: "70%",
        },
        {
            id: 6,
            Division: "4th",
            Percentage: "10%",
        },
        {
            id: 7,
            Division: "5th",
            Percentage: "25%",
        },
        {
            id: 8,
            Division: "6th",
            Percentage: "30%",
        },
        {
            id: 9,
            Division: "7th",
            Percentage: "56%",
        },
        {
            id: 10,
            Division: "8th",
            Percentage: "87%",
        },
        {
            id: 11,
            Division: "9th",
            Percentage: "90%",
        },
        {
            id: 12,
            Division: "10th",
            Percentage: "88%",
        },
        {
            id: 13,
            Division: "11th",
            Percentage: "28%",
        },
        {
            id: 14,
            Division: "12th",
            Percentage: "65%",
        },
    ]);
    const renderItems = () =>
        listitems.map((item) => {
            return (
                <ListItem key={item.id}>
                    <Top>
                        <Division>{item.Division}</Division>
                        <Percentage>{item.Percentage}</Percentage>
                    </Top>
                    <Bar>
                        <BlueBar style={{ width: item.Percentage }}></BlueBar>
                    </Bar>
                </ListItem>
            );
        });
    return (
        <BottomContainer>
            <LeftSection>
                <TopBar>
                    <Name>Contests</Name>
                    <YearDiv>
                        <select>
                            <option>2022</option>
                            <option>2023</option>
                            <option>2024</option>
                            <option>2025</option>
                        </select>
                        <Dropdown>
                            <img
                                src={
                                    require("../../../assets/images/dropdown.svg")
                                        .default
                                }
                                alt="dropdown"
                            />
                        </Dropdown>
                    </YearDiv>
                </TopBar>
                <GraphDiv>
                    <Graph />
                </GraphDiv>
            </LeftSection>
            <RightSection>
                <TopDiv>
                    <LeftHead>
                        <Tittle>Students</Tittle>
                    </LeftHead>
                    <RightHead>
                        <Class>Class</Class>
                        <Class className="Age">Age</Class>
                    </RightHead>
                </TopDiv>
                <BarLists>{renderItems()}</BarLists>
            </RightSection>
        </BottomContainer>
    );
}
export default DashboardBottom;
const BottomContainer = styled.div`
    display: flex;
    width: 100%;
    padding-top: 20px;
`;
const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Name = styled.h3`
    font-family: "poppins-medium";
    font-size: 17px;
`;
const Year = styled.span`
    font-size: 15px;
`;
const LeftSection = styled.div`
    background: #fff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    width: 64%;
    padding: 30px;
    border-radius: 7px;
    position: relative;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
`;
const RightSection = styled.div`
    width: calc(100% - 64%);
    padding: 16px 15px 35px 20px;
    background: #fff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    margin-left: 15px;
    border-radius: 7px;
`;
const TopDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const LeftHead = styled.div``;
const Tittle = styled.h3`
    font-family: "poppins-medium";
    font-size: 20px;
`;
const RightHead = styled.div`
    display: flex;
    font-family: "poppins-semibold";
`;
const Class = styled.span`
    font-size: 16px;
    font-family: "poppins-regular";
    &.Age {
        margin-left: 10px;
        font-size: 16px;
        font-family: "poppins-regular";
        color: #202020;
        opacity: 0.4;
    }
`;
const Age = styled.span`
    font-size: 15px;
    margin-left: 7px;
    color: #000000;
    opacity: 0.5;
`;
const BarLists = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 13px;
`;
const ListItem = styled.div`
    width: 100%;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
`;
const Division = styled.span`
    font-family: "poppins-regular";
    font-size: 14px;
`;
const Percentage = styled.span`
    font-family: "poppins-regular";
    font-size: 13px;
    color: #000000;
    opacity: 0.5;
`;
const Bar = styled.div`
    background: #eff3f9;
    width: 100%;
    border-radius: 13.6286px;
    height: 12px;
    position: relative;
`;
const BlueBar = styled.div`
    height: 11px;
    background: blue;
    content: "";
    position: absolute;
    left: 0px;
    border-radius: 13.6286px;
    background-image: linear-gradient(to right, #4684ef, #00b6f4);
`;
const YearDiv = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    font-family: "poppins-regular";
    color: #545454;
    select {
        cursor: pointer;
    }
`;
const Dropdown = styled.div`
    width: 10px;
    cursor: pointer;
`;
const GraphDiv = styled.div`
    display: block;
    position: absolute;
    top: 65px;
    left: 0x;
    left: 0px;
    width: 100%;
    overflow-y: hidden;
`;
