import React from "react";
import styled from "styled-components";

import { Link, NavLink, Outlet } from "react-router-dom";

export default function ContestMenuTab() {
    return (
        <SubheadDiv>
            <SubHeadList>
                <SubHead type="sl">All Contests</SubHead>
                <SubHead>Ongoing Contests</SubHead>
                <SubHead>Completed Contests</SubHead>
            </SubHeadList>
            <ViewButtonDiv>
                <RightButton to="/contestgrid" activeClassName="active">
                    <img
                        src={
                            require("../../../assets/images/grid-1.svg").default
                        }
                        alt="grid"
                    />
                </RightButton>
                <RightButton to="/contestscreen" activeClassName="active">
                    <img
                        src={
                            require("../../../assets/images/align-justify.svg")
                                .default
                        }
                        alt="alignimage"
                    />
                </RightButton>
            </ViewButtonDiv>
        </SubheadDiv>
    );
}
const SubHeadList = styled.div`
    display: flex;
    background: #ffffff;
    border-radius: 14px;
    width: 50%;
    padding: 7px 11px;
`;
const SubHead = styled.span`
    background: #ffffff;
    border-radius: 14px;
    text-align: center;
    margin: 0 auto;
    padding: 4px 15px;
    font-size: 15px;
    font-family: "poppins-regular";
    line-height: 1.3;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: #eff3f9;
    }
`;
const SubheadDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    width: 100%;
`;

const ViewButtonDiv = styled.div`
    display: flex;
`;
const RightButton = styled(NavLink)`
    margin-right: 10px;
    width: 30px;
    padding: 5px;
    &.active {
        background: #eff3f9;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
`;
