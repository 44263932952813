import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../general/Authentication/Login";
import AppRouter from "./AppRouter";

function MainRouter() {
    const [isLogin, setLogin] = useState(true);
    const [isLogout, setLogout] = useState(true);

    return (
        <Routes>
            {isLogin ? (
                <Route
                    path="/*"
                    element={<Login isLogin={isLogin} setLogin={setLogin} />}
                />
            ) : (
                <Route path="/*" element={<AppRouter />} />
            )}
        </Routes>
    );
}

export default MainRouter;
