import React from "react";
import DashboardTopCards from "./DashboardTopCards";
import styled from "styled-components";

import DashboardBottom from "./DashboardBottom";

export default function Dashboard() {
    return (
        <DashboardDiv id="center">
            <DashboardTopCards />
            <DashboardBottom />
        </DashboardDiv>
    );
}

const DashboardDiv = styled.div``;
