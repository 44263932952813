import React from "react";
import styled from "styled-components";

function UserAdded({ issave }) {
    return (
        <>
            <MainContainer className={issave ? "active" : ""}>
                <ContentContainer>
                    <ImageContainer>
                        <img
                            src={
                                require("../../../../../assets/images/Succes.svg")
                                    .default
                            }
                            alt="Succes"
                        />
                    </ImageContainer>
                    <p>User added succesfully</p>
                    <CloseContainer>
                        <img
                            src={
                                require("../../../../../assets/images/x.svg")
                                    .default
                            }
                            alt="X"
                        />
                    </CloseContainer>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

export default UserAdded;

const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: #849fa8;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: scale(0);
    display: none;
    &.active {
        transform: scale(1);
        display: none;
    }
`;
const ContentContainer = styled.div`
    width: 25%;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    padding: 40px 15px;
    text-align: center;
    position: relative;
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 80%;
    }
    p {
        font-size: 14px;
        margin-top: 10px;
        /* @media all and (max-width: 1440px) {
            font-size: 13px;
        } */
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    width: 20%;
`;
const CloseContainer = styled.div`
    width: 5%;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
`;
