import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink, Outlet } from "react-router-dom";
import AddStudent from "../includes/User/adduser/AddStudent";
import Logout from "./Authentication/Logout";

export default function Sidebar() {
    const [isActive, setActive] = useState(false);
    const [isLogout, setLogout] = useState(false);
    return (
        <>
            <AddStudent isActive={isActive} setActive={setActive} />
            <Logout isLogout={isLogout} setLogout={setLogout} />
            <SidebarContainer>
                <TopSection>
                    <LogoDiv>
                        <img
                            src={
                                require("../../assets/images/logooo.svg")
                                    .default
                            }
                            alt="Logo"
                        />
                    </LogoDiv>
                </TopSection>
                <NavBar>
                    <Item>
                        <NavheadContainer to="/" activeclassname="active">
                            <Icon>
                                <img
                                    src={
                                        require("../../assets/images/grid.svg")
                                            .default
                                    }
                                    alt="Logo"
                                />
                            </Icon>
                            <Title>Dashboard</Title>
                        </NavheadContainer>
                    </Item>
                    <Item>
                        <NavheadContainer to="/user" activeclassname="active">
                            <Icon>
                                <img
                                    src={
                                        require("../../assets/images/users.svg")
                                            .default
                                    }
                                    alt="user-icon"
                                />
                            </Icon>
                            <Title>Users</Title>
                        </NavheadContainer>
                        <SubHead to="/AddUser" activeclassname="active">
                            Add User
                        </SubHead>
                    </Item>
                    <Item>
                        <NavheadContainer
                            to="/contestscreen"
                            activeclassname="active"
                        >
                            <Icon>
                                <img
                                    src={
                                        require("../../assets/images/Contest Icon.svg")
                                            .default
                                    }
                                    alt="Logo"
                                />
                            </Icon>
                            <Title>Contests</Title>
                        </NavheadContainer>
                        <SubHead to="/daistotcontest" activeclassname="active">
                            DaisToT Contests
                        </SubHead>
                        <SubHead to="/CreateContest" activeclassname="active">
                            Create Contest
                        </SubHead>
                        <SubHead to="/first" activeclassname="active">
                            Announce Winner
                        </SubHead>
                    </Item>
                    <Item>
                        <NavheadContainer
                            className="pendingVerification"
                            to="/Varify"
                            activeclassname="active"
                        >
                            <Icon>
                                <img
                                    src={
                                        require("../../assets/images/iconoir_twitter-verified-badge.svg")
                                            .default
                                    }
                                    alt="Logo"
                                />
                            </Icon>
                            <Title>Pending Verification</Title>
                        </NavheadContainer>
                    </Item>
                    <Item>
                        <LogoutContainer onClick={() => setLogout(!isLogout)}>
                            <Icon>
                                <img
                                    src={
                                        require("../../assets/images/log-out.svg")
                                            .default
                                    }
                                    alt="Logo"
                                />
                            </Icon>
                            <Title>Log Out</Title>
                        </LogoutContainer>
                    </Item>
                </NavBar>
            </SidebarContainer>
            <Outlet />
        </>
    );
}

const SidebarContainer = styled.section`
    background-image: linear-gradient(to right, #4684ef, #00b6f4);
    width: 280px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    @media all and (max-width: 1280px) {
    }
`;
const TopSection = styled.div`
    background-color: #fff;
    padding-top: 10px;
`;
const LogoDiv = styled.div`
    width: 50%;
    margin: 0px auto;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
`;
const NavBar = styled.div`
    padding: 75px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Item = styled.div`
    &:last-child {
        position: fixed;
        bottom: 20px;
    }
`;
const NavheadContainer = styled(NavLink)`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 8px;
    &.pendingVerification {
        margin-bottom: 0;
    }
    &.active {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
    }
`;
const Icon = styled.div`
    width: 20px;
    margin-right: 20px;
`;
const Title = styled.div`
    color: #fff;
    font-family: "poppins-semibold";
    cursor: pointer;
`;
const SubHead = styled(NavLink)`
    font-family: "poppins-regular";
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 5px 10px 5px 60px;
    cursor: pointer;
    display: block;
    &:last-child {
        margin-bottom: 20px;
    }

    &.active {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
    }
`;
const LogoutContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    color: #fff;
    padding: 5px 40px 5px 8px;
    &.active {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
    }
    /* &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
    } */
`;
