import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Contestlist({ data }) {
    return (
        <>
            <ListContainer>
                <TitleContainer>
                    <Titleitem type="sl">{data.sl}</Titleitem>
                    <Titleitem type="name">{data.Contestname}</Titleitem>
                    <Titleitem type="name">{data.Category}</Titleitem>
                    <Titleitem type="name">{data.Subcategory}</Titleitem>
                    <Titleitem>{data.Status}</Titleitem>
                    <Titleitem>{data.EndDate}</Titleitem>
                    <Titleitem>
                        <ActionDiv>
                            <Icon to="/">
                                <img
                                    src={
                                        require("../../../../assets/images/eye-1.svg")
                                            .default
                                    }
                                    alt="Image"
                                />
                            </Icon>
                            <Icon to="/">
                                <img
                                    src={
                                        require("../../../../assets/images/edit.svg")
                                            .default
                                    }
                                    alt="edit"
                                />
                            </Icon>
                            <Icon to="/profilecard">
                                <img
                                    src={
                                        require("../../../../assets/images/trash.svg")
                                            .default
                                    }
                                    alt="trash"
                                />
                            </Icon>
                        </ActionDiv>
                    </Titleitem>
                </TitleContainer>
            </ListContainer>
        </>
    );
}

const ListContainer = styled.div`
    width: 100%;
`;
const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    align-items: center;
`;
const Titleitem = styled.span`
    margin-right: 10px;
    background: #fff;
    padding: 17px 23px;
    font-family: "poppins-regular";
    font-size: 15px;
    align-items: center;
    border-radius: 6px;
    width: ${({ type }) =>
        type === "sl" ? "11%" : type == "name" ? "35%" : "22%"};
    &:first-child {
        padding: 17px 7px 16px 12px;
    }

    &:last-child {
        margin-right: 0;
    }
`;
const ActionDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Icon = styled(Link)`
    margin-right: 10px;
    width: 15px;
    &:last-child {
        margin-right: 0;
    }
`;
