import React from "react";
import styled from "styled-components";
import profile from "../../../../assets/images/Propic.svg";
import posttumb from "../../../../assets/images/Post.svg";
import CardList from "../../../includes/contests/contestscreen/CardList";

export default function Winner() {
    const datas = [
        {
            profilepicture: profile,
            name: "Albin Royson",
            post: posttumb,
            likes: "1000",
            points: "5",
            Prize: "1st Prize",
        },
        {
            profilepicture: profile,
            name: "Anna Joseph",
            post: posttumb,
            likes: "500",
            points: "2",
            Prize: "2nd Prize",
        },
        {
            profilepicture: profile,
            name: "Albin Royson",
            post: posttumb,
            likes: "120",
            points: "1",
            Prize: "3rd Prize",
        },
    ];
    return (
        <WinnerDiv>
            <WinnerContainer>
                <TopDiv>
                    <Head>Winners</Head>
                    <WinnerCardList id="center">
                        {datas.map((item) => (
                            <WinnerCard>
                                <Top>
                                    <Title>{item.Prize}</Title>
                                    <CloseButton>
                                        <img
                                            src={
                                                require("../../../../assets/images/close.svg")
                                                    .default
                                            }
                                        />
                                    </CloseButton>
                                </Top>
                                <Middle>
                                    <Post>
                                        <img src={item.post} alt="post" />
                                    </Post>
                                    <PlayButton>
                                        <img
                                            src={
                                                require("../../../../assets/images/play.svg")
                                                    .default
                                            }
                                            alt="play"
                                        />
                                    </PlayButton>
                                </Middle>
                                <Bottom>
                                    <ProfileDiv>
                                        <Propic>
                                            <img
                                                src={item.profilepicture}
                                                alt="ProPic"
                                            />
                                        </Propic>
                                        <ProfileName>{item.name}</ProfileName>
                                    </ProfileDiv>
                                    <LikeandPointsDiv>
                                        <LikeDiv>
                                            <LikeIcon>
                                                <img
                                                    src={
                                                        require("../../../../assets/images/redheart.svg")
                                                            .default
                                                    }
                                                    alt="redheart"
                                                />
                                            </LikeIcon>
                                            <LikeCount>
                                                {item.likes} likes
                                            </LikeCount>
                                        </LikeDiv>
                                        <PointsDiv>
                                            <PointIcon>
                                                <img
                                                    src={
                                                        require("../../../../assets/images/pointicon.svg")
                                                            .default
                                                    }
                                                    alt="point"
                                                />
                                            </PointIcon>
                                            <PointCount>
                                                {item.points} pts
                                            </PointCount>
                                        </PointsDiv>
                                    </LikeandPointsDiv>
                                </Bottom>
                            </WinnerCard>
                        ))}
                    </WinnerCardList>
                </TopDiv>
            </WinnerContainer>
            <Bottomdiv>
                <SelectWinner>Select Winners</SelectWinner>
                <CardListContainer id="center">
                    <CardList datas={datas} />
                </CardListContainer>
            </Bottomdiv>
        </WinnerDiv>
    );
}

const Bottomdiv = styled.div``;
const WinnerDiv = styled.div``;
const CardListContainer = styled.div``;
const PlayButton = styled.div`
    position: absolute;
    top: 50%;
    right: 50%;
`;

const SelectWinner = styled.h4`
    font-size: 20px;
    color: #000;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    width: calc(100% - 280px);
    margin-left: 280px;
`;
const WinnerContainer = styled.div`
    background: #fff;
`;

const TopDiv = styled.div`
    padding-bottom: 26px;
`;
const Head = styled.h3`
    text-align: center;
    width: calc(100% - 280px);
    margin-left: 280px;
    font-size: 20px;
    color: #000;
    padding: 15px;
`;
const WinnerCardList = styled.div`
    display: flex;
    /* flex-wrap: wrap; */
`;
const WinnerCard = styled.div`
    width: 32%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 56%;
    margin: 0px 0 0 133px;
    padding: 5px;
`;
const Title = styled.span`
    text-align: center;
    text-align: center;
    font-size: 12px;
`;
const CloseButton = styled.div`
    width: 20px;
`;
const Middle = styled.div`
    position: relative;
`;
const Post = styled.div`
    margin-bottom: 10px;
`;
const Bottom = styled.div``;
const ProfileDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Propic = styled.div`
    width: 60px;
    margin: 0 auto 10px;
`;
const ProfileName = styled.span`
    text-align: center;
    font-size: 15px;
`;
const LikeandPointsDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
`;
const LikeDiv = styled.div`
    display: flex;
    align-items: center;
`;
const LikeIcon = styled.div`
    width: 18px;
`;
const LikeCount = styled.div`
    font-size: 10px;
    margin-left: 10px;
`;
const PointsDiv = styled.div`
    display: flex;
`;
const PointIcon = styled.div`
    width: 12px;
`;
const PointCount = styled.div`
    font-size: 10px;
    margin-left: 10px;
`;
