import React from "react";
import { useState } from "react";
import styled from "styled-components";
import PasswordChangeSuccess from "../../../general/Authentication/modal/PasswordChangeSuccess";

function EditUserPassword({ ispassword, setPassword }) {
    const [issuccess, setSuccess] = useState(false);
    const [view, setView] = useState(false);

    return (
        <>
            <PasswordChangeSuccess
                issuccess={issuccess}
                setSuccess={setSuccess}
            />
            <Overlay
                onClick={() => setPassword(false)}
                className={ispassword ? "active" : ""}
            ></Overlay>
            <Conatainer className={ispassword ? "active" : ""}>
                <TopContainer>
                    <Headers>Change Password</Headers>
                    <MiddleContainer>
                        <form>
                            <label>
                                Enter old password
                                <input type="text" name="name" />
                            </label>
                            <label className="password">
                                Enter new password
                                <Input className="password">
                                    <input
                                        type={view ? "text" : "password"}
                                        name="name"
                                    />
                                    <Eye onClick={() => setView(!view)}>
                                        <img
                                            src={
                                                require("../../../../assets/images/eye.svg")
                                                    .default
                                            }
                                            alt="Eye"
                                        />
                                    </Eye>
                                </Input>
                            </label>
                            <label>
                                Confirm new password
                                <input
                                    type="password"
                                    name="name"
                                    className="last"
                                />
                            </label>
                        </form>
                    </MiddleContainer>
                </TopContainer>
                <BottomContainer>
                    <Button
                        onClick={() => {
                            setSuccess(true);
                            setPassword(false);
                        }}
                    >
                        SAVE
                    </Button>
                </BottomContainer>
                <ArrowContainer onClick={() => setPassword(false)}>
                    <img
                        src={
                            require("../../../../assets/images/arrow-right.svg")
                                .default
                        }
                        alt="X"
                    />
                </ArrowContainer>
            </Conatainer>
        </>
    );
}

export default EditUserPassword;

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 501;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
`;
const Conatainer = styled.section`
    width: 350px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 502;
    background: rgb(255, 255, 255);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    &.active {
        right: 0;
    }
`;
const Headers = styled.h5`
    text-align: center;
    font-size: 18px;
    font-family: "poppins-medium";
`;
const TopContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: space-between;
`;
const MiddleContainer = styled.div`
    width: 100%;
    form {
        label {
            font-size: 16px;
            color: grey;
        }
        input {
            width: 100%;
            display: flex;
            border-bottom: 1px solid grey;
            margin-bottom: 40px;
            font-size: 18px;
            font-family: "popppins-regular";
            &.last {
                margin-bottom: 0px;
            }
        }
    }
`;
const Input = styled.div`
    width: 100%;
    display: flex;
    input {
        font-size: 18px;
        font-family: "popppins-regular";
    }
    &.password {
        position: relative;
        /* margin-bottom:30px ; */
    }
`;
const Eye = styled.div`
    width: 18px;
    cursor: pointer;
    position: absolute;
    right: 0px;
`;
const BottomContainer = styled.div`
    width: 100%;
`;
const Button = styled.div`
    font-size: 14px;
    color: #fff;
    display: flex;
    margin: 0 auto;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    cursor: pointer;
`;
const ArrowContainer = styled.div`
    width: 6%;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
`;
