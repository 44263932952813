import React from "react";
import { useState } from "react";
import styled from "styled-components";

function Notification({ isactive, setActive }) {
    return (
        <>
            <Overlay
                onClick={() => setActive(false)}
                className={isactive ? "active" : ""}
            ></Overlay>
            <MainContainer className={isactive ? "active" : ""}>
                <Container>
                    <Header>New</Header>
                    <Newmsg>
                        <Msg>
                            <p>
                                <b>User One</b> uploaded ID card. Verify now.
                                <span>just now</span>
                            </p>
                        </Msg>
                        <Msg>
                            <p>
                                <b>User One</b> uploaded ID card. Verify now.
                                <span>just now</span>
                            </p>
                        </Msg>
                        <Msg>
                            <p>
                                <b>User One</b> uploaded ID card. Verify now.
                                <span>just now</span>
                            </p>
                        </Msg>
                        <Msg>
                            <p>
                                <b>User One</b> uploaded ID card. Verify now.
                                <span>just now</span>
                            </p>
                        </Msg>
                        <Msg>
                            <p>
                                <b>Contest One</b> completed. Announce the
                                winner now.
                                <span>2 minutes ago</span>
                            </p>
                        </Msg>
                        <Msg>
                            <p>
                                <b>Contest One</b> completed. Announce the
                                winner now.
                                <span>2 minutes ago</span>
                            </p>
                        </Msg>
                    </Newmsg>
                    <Header>Earlier</Header>
                    <Earliermsg>
                        <Msg>
                            <p>
                                <b>Contest One</b> completed. Announce the
                                winner now.
                                <span>2 minutes ago</span>
                            </p>
                        </Msg>
                        <Msg>
                            <p>
                                <b>Contest One</b> completed. Announce the
                                winner now.
                                <span>2 minutes ago</span>
                            </p>
                        </Msg>
                    </Earliermsg>
                </Container>
            </MainContainer>
        </>
    );
}

export default Notification;

const Overlay = styled.div`
    &.active {
        width: 100%;
        height: 100vh;
        background: transparent;
        position: fixed;
        z-index: 5;
        top: 0;
        right: 0;
    }
`;
const MainContainer = styled.div`
    transform: scale(0);
    position: fixed;
    overflow-y:scroll ;
    top: 80px;
    right: 98px;
    z-index: 10;
    width: 320px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    height: 80vh;
    &.active {
        transform: scale(1);
        background: #fff;
        
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
`;
const Newmsg = styled.div`
    background: rgba(0, 182, 244, 0.08);
    padding: 15px 15px 5px 15px;
`;
const Header = styled.div`
    background: #fff;
    padding: 15px;
    font-family: "poppins-semibold";
`;
const Msg = styled.div`
    p {
        font-size: 14px;
        margin-bottom:10px;
        
        b {
            font-family: "poppins-semibold";
        }
        span {
            font-size: 10px;
            color: #b7b6b6;
        }
    }
`;
const Earliermsg = styled.div`
    padding: 15px;
`;
