import React from "react";
import CardList from "../../includes/contests/contestscreen/CardList";
import styled from "styled-components";
import ProfileCard from "../../includes/contests/contestscreen/ProfileCard";
import profile from "../../../assets/images/Propic.svg";
import posttumb1 from "../../../assets/images/contest1.svg";
import posttumb2 from "../../../assets/images/contest2.svg";
import posttumb3 from "../../../assets/images/contest3.svg";
import posttumb4 from "../../../assets/images/contest4.svg";

import PostMenuTab from "../../includes/contests/contestscreen/PostMenuTab";

export default function EyeViewContestScreen() {
    const datas = [
        {
            profilepicture: profile,
            name: "Albin Royson",
            post: posttumb1,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "Albin Royson",
            post: posttumb2,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "Albin Royson",
            post: posttumb3,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "Albin Royson",
            post: posttumb4,
            likes: "120",
            time: "1",
        },
    ];
    return (
        <EyeViewScreenDiv>
            <ProfleSideBarDiv>
                <ProfileCard />
            </ProfleSideBarDiv>
            <TopSection id="profile">
                <PostMenuTab />
                <PostCardlist>
                    {datas.map((item) => (
                        <PostCard>
                            <Topdiv>
                                <LeftContainer>
                                    <Propic>
                                        <img
                                            src={item.profilepicture}
                                            // alt="ProfilePhoto"
                                        />
                                    </Propic>
                                    <Name> {item.name}</Name>
                                </LeftContainer>
                                <RightContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/more-horizontal.svg")
                                                .default
                                        }
                                        alt="more"
                                    />
                                </RightContainer>
                            </Topdiv>
                            <MiddileDiv>
                                <Post>
                                    <img src={item.post} alt="post" />
                                </Post>
                                {/* <PlayButton>
                                    <img
                                        src={
                                            require("../../../assets/images/Video-Play-Button.svg")
                                                .default
                                        }
                                        alt="playbutton"
                                    />
                                </PlayButton> */}
                            </MiddileDiv>
                            <BottomDiv>
                                <LikeSection>
                                    <LoveDiv>
                                        <Left>
                                            <LoveIcon>
                                                <img
                                                    src={
                                                        require("../../../assets/images/heart (1).svg")
                                                            .default
                                                    }
                                                    alt="heart"
                                                />
                                            </LoveIcon>
                                            <LikeCount>
                                                {item.likes} likes
                                            </LikeCount>
                                        </Left>

                                        {/* <StarSection>
                                            <img
                                                src={
                                                    require("../../../assets/images/star.svg")
                                                        .default
                                                }
                                                alt="star"
                                            />
                                        </StarSection> */}
                                    </LoveDiv>
                                    <PostTime>{item.time} minutes ago</PostTime>
                                </LikeSection>
                            </BottomDiv>
                        </PostCard>
                    ))}
                </PostCardlist>
            </TopSection>
        </EyeViewScreenDiv>
    );
}

const EyeViewScreenDiv = styled.div`
    display: flex;
`;
const TopSection = styled.div``;

const Post = styled.div``;

const BottomDiv = styled.div``;
const ProfleSideBarDiv = styled.div``;

const PostCardlist = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding-bottom: 20px;
    margin-top: 55px;
`;
const PostCard = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0 auto;
`;
const Topdiv = styled.div`
    display: flex;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
`;
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Propic = styled.div`
    width: 35px;
    margin-right: 10px;
`;
const Name = styled.div`
    font-size: 13px;
    font-family: "poppins-semibold";
`;
const RightContainer = styled.div``;
const MiddileDiv = styled.div`
    margin-bottom: 10px;
    position: relative;
`;
const PlayButton = styled.div`
    position: absolute;
    width: 30px;
    z-index: 10px;
    left: 42%;
    top: 39%;
`;
// const Post = styled.div``;
// const BottomDiv = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// `;
const LikeSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const LoveDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const LoveIcon = styled.div`
    width: 20px;
    margin-right: 10px;
`;
const LikeCount = styled.span`
    font-size: 10px;
    font-family: "poppins-semibold";
`;
const PostTime = styled.span`
    font-size: 10px;
`;
const StarSection = styled.div``;
const Left = styled.div`
    display: flex;
    align-items: center;
`;
