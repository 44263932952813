import { React, useState } from "react";
import styled from "styled-components";
import CardList from "../../../includes/contests/contestscreen/CardList";
import Contestlist from "../../../includes/contests/contestscreen/Contestlist";

import profile from "../../../../assets/images/Propic.svg";
import posttumb from "../../../../assets/images/Post.svg";
import cinidanncepost1 from "../../../../assets/images/cinidance-post1.svg";
import cinidanncepost2 from "../../../../assets/images/cinidance-post2.svg";
import cinidanncepost3 from "../../../../assets/images/cinidance-post3.svg";
import cinidanncepost4 from "../../../../assets/images/cinidance-post4.svg";
import cinidanncepost5 from "../../../../assets/images/cinidancepost5.svg";
import cinidanncepost6 from "../../../../assets/images/cinidance-post6.svg";
import { ProgramContext } from "../../../../App";
import SelectWinnerModal from "../../../includes/contests/announcewinner/modal/SelectWinnerModal";

export default function SelectWinners() {
    const [isWinner, setWinner] = useState(false);
    const datas = [
        {
            profilepicture: profile,
            name: "albin Royson",
            post: cinidanncepost1,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "albin Royson",
            post: cinidanncepost2,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "albin Royson",
            post: cinidanncepost3,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "albin Royson",
            post: cinidanncepost2,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "albin Royson",
            post: cinidanncepost3,
            likes: "120",
            time: "1",
        },
        {
            profilepicture: profile,
            name: "albin Royson",
            post: cinidanncepost1,
            likes: "120",
            time: "1",
        },
    ];
    return (
        <>
            <SelectWinnerModal isWinner={isWinner} setWinner={setWinner} />
            <TotalContainer>
                <SelectWinnersdiv>
                    <Head>
                        <h4>Select Winner</h4>
                    </Head>
                </SelectWinnersdiv>
                <PostCardlist id="center">
                    {datas.map((item) => (
                        <PostCard>
                            <Topdiv>
                                <LeftContainer>
                                    <Propic>
                                        <img
                                            src={item.profilepicture}
                                            alt="ProfilePhoto"
                                        />
                                    </Propic>
                                    <Name> {item.name}</Name>
                                </LeftContainer>
                                <RightContainer>
                                    <img
                                        src={
                                            require("./../../../../assets/images/more-horizontal.svg")
                                                .default
                                        }
                                        alt="more"
                                    />
                                </RightContainer>
                            </Topdiv>
                            <MiddileDiv>
                                <Post>
                                    <img src={item.post} alt="post" />
                                </Post>
                                <PlayButton>
                                    <img
                                        src={
                                            require("../../../../assets/images/Video-Play-Button.svg")
                                                .default
                                        }
                                        alt="playbutton"
                                    />
                                </PlayButton>
                            </MiddileDiv>
                            <BottomDiv>
                                <LikeSection>
                                    <LoveDiv>
                                        <Left>
                                            <LoveIcon>
                                                <img
                                                    src={
                                                        require("../../../../assets/images/heart (1).svg")
                                                            .default
                                                    }
                                                    alt="heart"
                                                />
                                            </LoveIcon>
                                            <LikeCount>
                                                {item.likes} likes
                                            </LikeCount>
                                        </Left>

                                        <StarSection>
                                            <img
                                                src={
                                                    require("../../../../assets/images/star.svg")
                                                        .default
                                                }
                                                alt="star"
                                            />
                                        </StarSection>
                                    </LoveDiv>
                                    <PostTime>{item.time} minutes ago</PostTime>
                                </LikeSection>
                            </BottomDiv>
                            <WinnerButton onClick={() => setWinner(true)}>
                                Select as winner
                            </WinnerButton>
                        </PostCard>
                    ))}
                </PostCardlist>
            </TotalContainer>
        </>
    );
}
const TotalContainer = styled.div``;
const SelectWinnersdiv = styled.div``;
const Head = styled.div`
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    width: calc(100% - 280px);
    margin-left: 280px;
    h4 {
        font-size: 20px;
        color: #000;
        padding: 15px;
        text-align: center;
    }
`;
const Carddiv = styled.div``;
const PostCardlist = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding-bottom: 20px;
`;
const PostCard = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
`;
const Topdiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Propic = styled.div`
    width: 35px;
    margin-right: 10px;
`;
const Name = styled.div`
    font-size: 13px;
    font-family: "poppins-semibold";
`;
const RightContainer = styled.div``;
const MiddileDiv = styled.div`
    margin-bottom: 10px;
    position: relative;
`;
const PlayButton = styled.div`
    position: absolute;
    width: 30px;
    z-index: 10px;
    left: 42%;
    top: 39%;
`;
const Post = styled.div``;
const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LikeSection = styled.div`
    width: 100%;
`;
const LoveDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const LoveIcon = styled.div`
    width: 20px;
    margin-right: 10px;
`;
const LikeCount = styled.span`
    font-size: 10px;
    font-family: "poppins-semibold";
`;
const PostTime = styled.span`
    font-size: 10px;
`;
const StarSection = styled.div``;
const Left = styled.div`
    display: flex;
    align-items: center;
`;
const WinnerButton = styled.div`
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    width: 49%;
    margin-left: 162px;
    border-radius: 8px;
    background: linear-gradient(90deg, #4684ef 0%, #00b6f4 100%);
    width: 42%;
    margin-left: 186px;
    border-radius: 4px;
    font-size: 15px;
    padding: 4px 13px;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
`;
